import React, { createContext, useState, useEffect } from 'react';
import useGlobalSettings from "../../hooks/useGlobalSettings.js";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [role, setRole] = useState();
  const { getSetting } = useGlobalSettings();


  useEffect(() => {
    setRole(String(getSetting("userRole")));
  }, [role]);

  return (
    <AuthContext.Provider value={{ role, setRole }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
