import React from 'react';
import StudentAddForm from './users/students/StudentAddForm';

const EmbedStudentAddFormPage = () => {
  return (
    <div>
      <StudentAddForm />
    </div>
  );
};

export default EmbedStudentAddFormPage;


