import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
import MiniSidebar from "../../sidebar/MiniSidebar.js";
import Sidebar from "../../sidebar/Sidebar.js";
import TopBar from "../../sidebar/TopBar.js";
import ReactModal from "react-modal";
import { API_URL } from "../../../utils/config.js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { fetchData } from "../../../utils/helper.js";
import BusinessProfileView from "./BusinessProfileView.js";
import BusinessProfileForm from "./BusinessProfileForm.js";
import GeneralSettings from "./GeneralSettings.js";
import SMTPSettings from "./SMTPSettings.js";
import FamilyAccountSettings from "./FamilyAccountSettings.js";
import PolicySettings from "./PolicySettings.js";
import StudentPortalSettings from "./StudentPortalSettings.js";
import EmailTemplates from "./EmailTemplates.js";
import MileageSettings from "./MileageSettings.js";
import SalesTaxSettings from "./SalesTaxSettings.js";
import { AuthContext } from '../../registerLogin/AuthContext.js';
import { SettingsContext } from '../../../contextApi/SettingsContext.js';
import { convertDateForSentInApi } from '../../../services/dateService';

const customStyles = {
  content: {
    width: "60%",
    height: "80%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "#6c5a5669",
  },
};

const BusinessSettings = () => {
  const { userData, sidebarToggle, token, setUserId, userId, setUserData, fetchTimeZones, allTimeZones, fetchDateFormats, dateFormats, fetchRegions, allRegions, fetchCurrencies, allCurrencies } = useUserDataContext();
  const navigate = useNavigate();
  const { settings, setSettings } = useContext(SettingsContext);

  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const { role } = useContext(AuthContext);
  const [error, setError] = useState({});
  const [initial, setInitial] = useState('');
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [brandLogo, setBrandLogo] = useState("");
  const [invoiceLogo, setInvoiceLogo] = useState("");
  const [regionLabel, setRegionLabel] = useState("");
  const [currencyLabel, setCurrencyLabel] = useState("");
  const [timezoneLabel, setTimezoneLabel] = useState("");
  const [showDayOfMonth, setShowDayOfMonth] = useState(false);
  const [showSpecificDate, setShowSpecificDate] = useState(false);
  const [showFixedAmountFields, setShowFixedAmountFields] = useState(false);
  const [showPercentageAmountFields, setShowPercentageAmountFields] = useState(false);
  const [paymentMethodTags, setPaymentMethodTags] = useState([]);
  const [chargeCategoryId, setChargeCategoryId] = useState("");
  const [isAllowToSetMakeUpLimit, setIsAllowToSetMakeUpLimit] = useState(false);
  const [isAllowToSetExpireUnUsedCredits, setIsAllowToSetExpireUnUsedCredits] = useState(false);
  const [emailTimeFrame, setEmailTimeFrame] = useState("");

  //Formdata
  const [formData, setFormData] = useState({
    business_name: '',
    logo: '',
    phone_number: '',
    business_address: '',
    region: '',
    timeZone: '',
    currency: '',

    // General Setting
    scheduling_conflicts: 1,
    tutor_name_format: "{First} {Last}",
    student_name_format: "{First} {Last}",
    parent_name_format: "{First} {Last}",
    price_display_format: "{CurrencySymbol}{Price}",

    //SMTP Setting
    mail_driver: "",
    mail_host: "",
    mail_port: "",
    mail_encryption: "",
    mail_username: "",
    mail_password: "",

    //Family Account Setting
    payment_methods: "",
    balance_date: "",
    day_of_month: "",
    specific_date: null,
    late_payment_fee: "none",
    late_fee_amount: "",
    late_fee_percentage: "",
    late_fee_apply_after_days: "",
    send_invoice_notification: 0,
    send_over_due_invoice_reminder: 0,
    email_timeframe: "",
    invoice_name: "",
    invoice_logo: "",
    invoice_footer_text: "",
    invoice_accent_color: "#262626",
    pro_data_calculation: 0,
    generate_invoice_number: 0,
    negative_invoices: "zero_due",
    show_location_on_invoice: 0,
    group_charges_of_multi_students: 0,
    include_sales_tax_in_amount: 0,

    //Policy Settings
    allow_event_cancellation: 0,
    advance_cancellation_hours: "",
    log_in_app_notification_when_cancellation_occurs: 0,
    event_is_cancelled_before_deadline: "",
    convert_event_to_make_up_slot_before_deadline: 0,
    event_is_cancelled_after_deadline: "",
    convert_event_to_make_up_slot_after_deadline: 0,
    policy_text: "",
    allow_event_sign_up: 0,
    allow_sign_up_to_hours: "",
    log_in_app_notification_when_a_sign_up_occurs: 0,
    make_up_credit_category: "no_change",
    limit_make_up_credits: 0,
    max_number_of_makeup_credit: "",
    makeup_credit_issued_from_date: null,
    expire_unused_make_up_credits: 0,
    expire_unused_makeup_credit_after: "",

    //Student Portal Settings
    allow_edit_contact_info: 0,
    allow_different_timezone: 0,
    allow_partial_online_payments: 0,
    show_all_attendees: 0,
    show_tutor_contact: 0,
    show_tutor_address: 0,
    show_make_up_credits: 0,
    show_student_contact_list: 0,
    show_account_invoices: 0,
    initial_page: "",
    calendar_default_view: "",
    calendar_color_code: "",
  });

  useEffect(() => {
    fetchData(role, setUserData, setUserId, setSettings);
    fetchTimeZones();
    fetchDateFormats();
    fetchRegions();
    fetchCurrencies();
  }, []);

  useEffect(() => {
    if (userData.business_data) {
      const { business_data } = userData;
      setFormData({
        business_name: business_data.business_name || '',
        logo: business_data.logo || '',
        phone_number: business_data.phone_number || '',
        business_address: business_data.business_address || '',
        region: business_data.region || '',
        timeZone: business_data.timeZone || '',
        currency: business_data.currency_id || '',

        // General Setting
        scheduling_conflicts: business_data.scheduling_conflicts || 1,
        tutor_name_format: business_data.tutor_name_format || "{First} {Last}",
        student_name_format: business_data.student_name_format || "{First} {Last}",
        parent_name_format: business_data.parent_name_format || "{First} {Last}",
        price_display_format: business_data.price_display_format || "{CurrencySymbol}{Price}",

        //SMTP Setting
        mail_driver: business_data.mail_driver || "",
        mail_host: business_data.mail_host || "",
        mail_port: business_data.mail_port || "",
        mail_encryption: business_data.mail_encryption || "",
        mail_username: business_data.mail_username || "",
        mail_password: business_data.mail_password || "",

        //Family Account Setting
        balance_date: business_data.balance_date || "",
        day_of_month: business_data.day_of_month || "",
        specific_date: business_data.specific_date || null,
        late_payment_fee: business_data.late_payment_fee || "none",
        late_fee_amount: business_data.late_fee_amount || "",
        late_fee_percentage: business_data.late_fee_percentage || "",
        late_fee_apply_after_days: business_data.late_fee_apply_after_days || "",
        send_invoice_notification: business_data.send_invoice_notification || 0,
        send_over_due_invoice_reminder: business_data.send_over_due_invoice_reminder || 0,
        email_timeframe: business_data.email_timeframe || "",
        invoice_name: business_data.invoice_name || "",
        invoice_footer_text: business_data.invoice_footer_text || "",
        invoice_accent_color: business_data.invoice_accent_color || "#262626",
        pro_data_calculation: business_data.pro_data_calculation || 0,
        generate_invoice_number: business_data.generate_invoice_number || 0,
        negative_invoices: business_data.negative_invoices || "zero_due",
        show_location_on_invoice: business_data.show_location_on_invoice || 0,
        group_charges_of_multi_students: business_data.group_charges_of_multi_students || 0,
        include_sales_tax_in_amount: business_data.include_sales_tax_in_amount || 0,

        //Policy Settings
        allow_event_cancellation: business_data.allow_event_cancellation || 0,
        advance_cancellation_hours: business_data.advance_cancellation_hours || "",
        log_in_app_notification_when_cancellation_occurs: business_data.log_in_app_notification_when_cancellation_occurs || 0,
        event_is_cancelled_before_deadline: business_data.event_is_cancelled_before_deadline || "",
        convert_event_to_make_up_slot_before_deadline: business_data.convert_event_to_make_up_slot_before_deadline || 0,
        event_is_cancelled_after_deadline: business_data.event_is_cancelled_after_deadline || "",
        convert_event_to_make_up_slot_after_deadline: business_data.convert_event_to_make_up_slot_after_deadline || 0,
        policy_text: business_data.policy_text || "",
        allow_event_sign_up: business_data.allow_event_sign_up || 0,
        allow_sign_up_to_hours: business_data.allow_sign_up_to_hours || "",
        log_in_app_notification_when_a_sign_up_occurs: business_data.log_in_app_notification_when_a_sign_up_occurs || 0,
        make_up_credit_category: business_data.make_up_credit_category || "no_change",
        limit_make_up_credits: business_data.limit_make_up_credits || 0,
        max_number_of_makeup_credit: business_data.max_number_of_makeup_credit || "",
        makeup_credit_issued_from_date: business_data.makeup_credit_issued_from_date || null,
        expire_unused_make_up_credits: business_data.expire_unused_make_up_credits || 0,
        expire_unused_makeup_credit_after: business_data.expire_unused_makeup_credit_after || "",

        //Student Portal Settings
        allow_edit_contact_info: business_data.allow_edit_contact_info || 0,
        allow_different_timezone: business_data.allow_different_timezone || 0,
        allow_partial_online_payments: business_data.allow_partial_online_payments || 0,
        show_all_attendees: business_data.show_all_attendees || 0,
        show_tutor_contact: business_data.show_tutor_contact || 0,
        show_tutor_address: business_data.show_tutor_address || 0,
        show_make_up_credits: business_data.show_make_up_credits || 0,
        show_student_contact_list: business_data.show_student_contact_list || 0,
        show_account_invoices: business_data.show_account_invoices || 0,
        initial_page: business_data.initial_page || "",
        calendar_default_view: business_data.calendar_default_view || "",
        calendar_color_code: business_data.calendar_color_code || "",
      });

      setEmailTimeFrame(business_data.email_timeframe);
      setBrandLogo(business_data.business_logo);
      setInvoiceLogo(business_data.invoice_logo);
      setShowDayOfMonth(business_data.balance_date == "dayOfMonth" ?? true);
      setShowSpecificDate(business_data.balance_date == "specificDate" ?? true);
      setShowFixedAmountFields(business_data.late_payment_fee == "fixed" ?? true);
      setShowPercentageAmountFields(business_data.late_payment_fee == "percentage" ?? true);
      setPaymentMethodTags(business_data?.payment_methods == null ? [] : business_data.payment_methods);
      setChargeCategoryId(business_data.category_for_late_fees);
      setIsAllowToSetMakeUpLimit((business_data.limit_make_up_credits == 1) ? true : false);
      setIsAllowToSetExpireUnUsedCredits((business_data.expire_unused_make_up_credits == 1) ? true : false);
    }
  }, [userData]);

  useEffect(() => {
    const myRegion = userData?.business_data?.region;
    if (myRegion) {
      const selected_region = allRegions.find(tz => tz.id == myRegion);
      setRegionLabel(selected_region?.region_label);
    }
  }, [allRegions]);

  useEffect(() => {
    const myTimeZone = userData?.business_data?.timeZone;
    if (myTimeZone) {
      const selected_timezone = allTimeZones.find(tz => tz.id == myTimeZone);
      setTimezoneLabel(selected_timezone?.offset);
    }
  }, [allTimeZones]);

  useEffect(() => {
    currencyHandlerbyBusiness(allCurrencies);
  }, [allCurrencies]);

  const currencyHandlerbyBusiness = async (currencyData) => {
    const formattedCurrencies = currencyData.map(currency => ({
      value: currency.id,
      label: `${currency.name} (${currency.code} - ${currency.symbol})`
    }));
    setCurrencyOptions(formattedCurrencies);
    const myCurrency = userData?.business_data?.currency_id;
    if (myCurrency) {
      const selected_currency = formattedCurrencies.find(tz => tz.value == myCurrency);
      setCurrencyLabel(selected_currency?.label);
    } else {
      const defaultCurrency = formattedCurrencies.find(currency => currency.label.includes("US Dollar (USD - $)"));
      if (defaultCurrency) {
        setFormData(prevState => ({
          ...prevState,
          currency: defaultCurrency.value
        }));
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'logo') {
      setFormData({ ...formData, logo: files[0] });
    } else if (name === 'invoice_logo') {
      setFormData({ ...formData, invoice_logo: files[0] });
    } else if (name == "region") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const dataLabel = selectedOption.getAttribute('data-label');
      setRegionLabel(dataLabel);
      setFormData({ ...formData, [name]: value });
    } else if (name == "timeZone") {
      const selectedOption = e.target.options[e.target.selectedIndex];
      const dataLabel = selectedOption.getAttribute('data-label');
      setTimezoneLabel(dataLabel);
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleCurrencyChange = (selectedOption) => {
    setCurrencyLabel(selectedOption?.label);
    setFormData(prevState => ({
      ...prevState,
      currency: selectedOption ? selectedOption.value : ""
    }));
  };

  const handleChangeGeneralSettings = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    }));
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    const formattedData = {
      ...formData,
      specific_date: convertDateForSentInApi(formData.specific_date),
      makeup_credit_issued_from_date: convertDateForSentInApi(formData.makeup_credit_issued_from_date),
      makeup_credit_issued_from_date: convertDateForSentInApi(formData.makeup_credit_issued_from_date),
      makeup_credit_issued_from_date: convertDateForSentInApi(formData.makeup_credit_issued_from_date),
    };
    const config = {
      method: "PATCH",
      url: `${API_URL}savedata`,
      data: {
        tenant_data: {
          ...formattedData, user_id: userId,
          payment_methods: paymentMethodTags,
          category_for_late_fees: chargeCategoryId,
          email_timeframe: emailTimeFrame,
        }
      },
    };
    await axios(config)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsOpen(false);
        const settingData = response.data.data.tenantinfo;
        Object.keys(settingData).forEach(key => {
          sessionStorage.setItem(key, settingData[key]);
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="wrapper businesssetting">
      {sidebarToggle ? <MiniSidebar /> : <Sidebar />}
      <div className="main">
        <TopBar />
        <main className="content">
          <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <BusinessProfileView initial={initial}
                logo={brandLogo}
                businessName={formData.business_name}
                setIsOpen={setIsOpen}
                regionLabel={regionLabel}
                timezoneLabel={timezoneLabel}
                currencyLabel={currencyLabel}
              />
              <div className="col-xl-8 col-xxl-8">
                <GeneralSettings
                  handleChange={handleChangeGeneralSettings}
                  formData={formData}
                  formSubmit={formSubmit}
                />
                <SMTPSettings
                  handleChange={handleChange}
                  formData={formData}
                  formSubmit={formSubmit}
                />
                <FamilyAccountSettings
                  handleChange={handleChangeGeneralSettings}
                  formData={formData}
                  setFormData={setFormData}
                  formSubmit={formSubmit}
                  tags={paymentMethodTags}
                  setTags={setPaymentMethodTags}
                  chargeCategoryId={chargeCategoryId}
                  setChargeCategoryId={setChargeCategoryId}
                  showDayOfMonth={showDayOfMonth}
                  setShowDayOfMonth={setShowDayOfMonth}
                  showSpecificDate={showSpecificDate}
                  setShowSpecificDate={setShowSpecificDate}
                  showFixedAmountFields={showFixedAmountFields}
                  setShowFixedAmountFields={setShowFixedAmountFields}
                  showPercentageAmountFields={showPercentageAmountFields}
                  setShowPercentageAmountFields={setShowPercentageAmountFields}
                  invoiceLogo={invoiceLogo}
                  setInvoiceLogo={setInvoiceLogo}
                  emailTimeFrame={emailTimeFrame}
                  setEmailTimeFrame={setEmailTimeFrame}
                />
                <PolicySettings
                  handleChange={handleChangeGeneralSettings}
                  formData={formData}
                  setFormData={setFormData}
                  formSubmit={formSubmit}
                  isAllowToSetMakeUpLimit={isAllowToSetMakeUpLimit}
                  setIsAllowToSetMakeUpLimit={setIsAllowToSetMakeUpLimit}
                  isAllowToSetExpireUnUsedCredits={isAllowToSetExpireUnUsedCredits}
                  setIsAllowToSetExpireUnUsedCredits={setIsAllowToSetExpireUnUsedCredits}
                />
                <StudentPortalSettings
                  handleChange={handleChangeGeneralSettings}
                  formData={formData}
                  formSubmit={formSubmit}
                />
                <EmailTemplates />
                <MileageSettings
                  handleChange={handleChangeGeneralSettings}
                  formData={formData}
                  formSubmit={formSubmit}
                />
                <SalesTaxSettings />
              </div>
            </div>
          </div>
        </main>
        <ReactModal
          isOpen={modalIsOpen === "profile"}
          onRequestClose={() => setIsOpen(false)}
          contentLabel="Business Profile Modal"
          style={customStyles}>
          <BusinessProfileForm
            formData={formData}
            handleChange={handleChange}
            formSubmit={formSubmit}
            setIsOpen={setIsOpen}
            initial={initial}
            error={error}
            allRegions={allRegions}
            allTimeZones={allTimeZones}
            dateFormats={dateFormats}
            currencyOptions={currencyOptions}
            handleCurrencyChange={handleCurrencyChange}
            setBrandLogo={setBrandLogo}
            brandLogo={brandLogo}
            token={token}
          />
        </ReactModal>
      </div>
    </div>
  );
};

export default BusinessSettings;
