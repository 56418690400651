import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";
import "../assets/css/customDatepicker.css";
import { Link, useParams } from "react-router-dom";
import FetchInvoicesDatatable from "./FetchInvoicesDatatable.js";
import { getOwedBalance } from "../../../services/invoiceService.js";


const Invoice = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chargecat_name, set_chargecat_name] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const param = useParams();
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [owedBalance, setOwedBalance] = useState("");


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return <div className="row">
        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
            <div className="card flex-fill w-100">
                <div className="card-header w-100">
                    <div style={{ display: 'flex', flexDirection: 'column', }}>

                    </div>
                </div>
                <div className="card-body d-flex">
                    <div className="align-self-center w-100">
                        <FetchInvoicesDatatable setIsEdit={setIsEdit} setModalIsOpen={setModalIsOpen} set_chargecat_name={set_chargecat_name} setSelectedId={setSelectedId} id={param?.id} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Invoice;