import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import { Sidebar, MiniSidebar, TopBar } from "../sidebar";
import TinyMCE from "../TinyMCE.js";
import axios from "axios";
import { API_URL } from "../../utils/config.js";
import LanguageOption from "../LanguageOption.js";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { CKEditorComponent } from "../CK5Editor.js";
const EmailTemplates = () => {
  const {
    sidebarToggle,
    emailTemplate,
    emailTemplateData,
    handleEmailTemplate,
    emailOnchange,
    token,
  } = useUserDataContext();

  const navigate = useNavigate();
  const { t }= useTranslation()

  useEffect(() => {
    getShortcodes();
    emailTemplate();
  }, []);

  const getShortcodes = async () => {
    const validateconfig = {
      method: "GET",
      url: `${API_URL}etps`,
    };
    await axios(validateconfig)
      .then((response) => {
        console.log(response.data);
        if (response.data.success === true) {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />

        <main className="content">
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-12 col-xxl-12 choose-template">
                <h5>{t("Choose Template")}</h5>
                <select onChange={handleEmailTemplate}>
                  <option value="0">{t("Select an Option")}</option>
                  {emailTemplateData.map((item) => {
                    const { id, template_name } = item;
                    return (
                      <option key={id} value={id}>
                        {template_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {emailOnchange && (
                <>
                  {/* <TinyMCE /> */}
                  <CKEditorComponent/>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EmailTemplates;
