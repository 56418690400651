import React, { useEffect, useContext, useState, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import MiniSidebar from "../../../sidebar/MiniSidebar.js";
import Sidebar from "../../../sidebar/Sidebar.js";
import TopBar from "../../../sidebar/TopBar.js";
import "./assets/css/style.css";
import { useUserDataContext } from "../../../../contextApi/userDataContext.js";
import { useParams } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../../../utils/config.js";
import instructors from "../../assets/images/Instructors.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FetchStudyLog from "../FetchStudyLog.js";
import FetchAttendanceLog from "../FetchAttendanceLog.js";
import FetchStudentFamilyContactDatatable from "../FetchStudentFamilyContactDatatable.js";
import lending from "../assets/images/sms.svg"
import ReactModal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { customStyles, passwordStyles, availStyles } from "../../my-preferences/style.js";
import { getAllStudents } from "../../../../services/calenderService.js";
import CurrencyService from "../../../../services/currencyService.js";
import { SettingsContext } from '../../../../contextApi/SettingsContext.js';
import IconDatePicker from "../../../form/date-picker-input/IconDatePicker";
import { convertDateForSentInApi } from '../../../../services/dateService';

const StudentEditDetails = () => {
  const {
    sidebarToggle,
    token,
    getTutor,
    allTutors,
    fetchCategory,
    allCategory,
    getStudentStatus,
    statusList,
    fetchStudentAttendanceSummery,
    studentAttendanceSummery,
    userData,
    isDarkMode,
    fetchCourseData,
    courseData
  } = useUserDataContext();
  const { settings } = useContext(SettingsContext);
  const [initial, setInitial] = useState("");
  const [studyLogDate, setStudyLogDate] = useState(new Date());
  const [attendanceLogDate, setAttendanceLogDate] = useState(new Date());
  const [studentFetchData, setStudentFetchData] = useState({});
  const [modalIsOpen, setIsOpens] = useState(false);
  const [formData, setFormData] = useState({});
  const [profilePhoto, setProfilePhoto] = useState("");
  const [profilePicData, setProfilePicData] = useState({});
  const [error, setError] = useState({});
  const [defaultLessonCat, setDefaultLessonCat] = useState("");
  const [defaultLessonLength, setDefaultLessonLength] = useState("30");
  const [price, setPrice] = useState("30.00");
  const [tutorId, setTutorId] = useState();
  const [makeUpCredits, setMakeUpCredits] = useState("0");
  const [assignTutor, setAssignTutor] = useState({});
  const [tutors, setTutors] = useState([]);
  const [defaultBilling, setDefaultBilling] = useState("per_lesson_charge");
  const [checked, setChecked] = React.useState(false);
  const [checkedParentAccess, setCheckedParentAccess] = React.useState(false);
  const [attendFlag, setAttendFlag] = useState(false);
  const [preferenceDisabled, setPreferenceDisabled] = useState(true);
  const [familyDetailFlag, setFamilyDetailFlag] = useState(false);
  const [familyDetailDisabled, setFamilyDetailDisabled] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusVal, setSelectedStatusVal] = useState("");
  const [selectedStatusColor, setSelectedStatusColor] = useState("");
  const [selectedStatusBgColor, setSelectedStatusBgColor] = useState("");
  const [isNoteEdited, setIsNoteEdited] = useState(false);
  const [studentGeneralEditFlag, setStudentGeneralEditFlag] = useState(false);
  const [defaultBillingEditFlag, setDefaultBillingEditFlag] = useState(false);
  const [defaultAutoInvoicesEditFlag, setDefaultAutoInvoicesEditFlag] = useState(false)
  const [selectedBilling, setSelectedBilling] = useState("");
  const [selectedAutoInvoicing, setSelectedAutoInvoicing] = useState("");
  const [isStudentEmailExists, setStudentEmailExists] = React.useState(false);
  const [isParentEmailExists, setParentEmailExists] = React.useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editTutorId, setEditTutorId] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const selectedCurrency = CurrencyService.getCurrency();
  const navigate = useNavigate();

  let { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);

  let hasEditAccess = true;
  
  if (userData.role_id == `${process.env.REACT_APP_PARENT_ROLE}`) {
    hasEditAccess = false;
  }

  const getTutorById = (id) => {
    return allTutors.find((tutor) => tutor.id === id);
  };

  // Memoize the coursesList to avoid unnecessary re-renders
  const coursesList = useMemo(() =>
    courseData.map((course) => ({
      value: course.id,
      label: course.course_name,
    })),
    [courseData]
  );
  
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#363636" : "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDarkMode ? "#363636" : "white",
        color: isDarkMode ? "#FFF" : "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const initializeStudents = async () => {
    const response = await getAllStudents();
    const students = response.data;
    if (students && students.length > 0) {
      setAllStudents(students);
      // Check if there's an ID from the URL, if so, set the selected student accordingly
      if (id) {
        const student = students.find(student => parseInt(student.id) === parseInt(id));
        if (student) {
          setSelectedStudent({ label: student.name, value: student.id });
        }
      }
    }
  };

  useEffect(() => {
    initializeStudents();
  }, [id])

  const onChangeSelectStudentHandler = (selectedOption) => {
    console.log("select option---------");
    setSelectedStudent(selectedOption)
    navigate(`/students/details/${selectedOption.value}`);
  }

  const fetchAssignTutors = async (id) => {

    const validateconfig = {
      method: "GET",
      url: `${API_URL}assigned-tutors/${id}`,
      data: {
        user_id: id,
      },
    };
    await axios(validateconfig)
      .then((response) => {
        console.log(response.data);
        setTutors(response.data.data);

      })
      .catch((error) => {
        console.log(error);

      });
  };

  const fetchStudentDetails = async (id) => {

    const validateconfig = {
      method: "GET",
      url: `${API_URL}student/details/${id}`,
      data: {
        user_id: id,
      },
    };
    await axios(validateconfig)
      .then((response) => {
        setChecked(response.data.data.enable_login_access);
        setStudentFetchData(response.data.data);
      })
      .catch((error) => {
        console.log(error);

      });
  };

  useEffect(() => {
    fetchStudentDetails(id);
    fetchCategory();
    fetchCourseData();
    getTutor();
    fetchAssignTutors(id);
    getStudentStatus();
    fetchStudentAttendanceSummery(id);

  }, [id]);

  const handleAssignTutor = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "default_lesson_cat") {
      setDefaultLessonCat(value);
    }

    if (name === "default_billing") {
      setDefaultBilling(value);
    }

    if (name === "default_lesson_length") {
      setDefaultLessonLength(value);
    }

    if (name === "price") {
      setPrice(value);
    }

    if (name === "make_up_credits") {
      setMakeUpCredits(value);
    }

    setAssignTutor({ ...assignTutor, [name]: value });
  };

  const saveAssignTutor = async (e) => {
    e.preventDefault();

    let selectedTutor = document.getElementById("tutor_id");

    assignTutor["student_id"] = id;
    assignTutor["tutor_id"] = selectedTutor?.value;

    if (defaultBilling === "per_lesson_charge") {
      assignTutor["per_lesson_charge"] = price;
    }

    if (defaultBilling === "per_month_charge") {
      assignTutor["per_month_charge"] = price;
    }

    if (defaultBilling === "per_hour_charge") {
      assignTutor["per_hour_charge"] = price;
    }

    if (!assignTutor.hasOwnProperty("default_lesson_cat")) {
      assignTutor["default_lesson_cat"] = defaultLessonCat;
    }

    if (!assignTutor.hasOwnProperty("make_up_credits")) {
      assignTutor["make_up_credits"] = makeUpCredits;
    }

    if (!assignTutor.hasOwnProperty("default_lesson_length")) {
      assignTutor["default_lesson_length"] = defaultLessonLength;
    }

    const config = {
      method: "POST",
      url: `${API_URL}map-tutor-student`,
      data: assignTutor,
    };
    await axios(config)
      .then((response) => {
        console.log(response);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });

        setIsOpen(false);
        closeModal();
        fetchAssignTutors(id);
        setAssignTutor({});
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.success === false) {
          setError(error.response.data.data);
        }
        setAssignTutor({});
      });
  };

  useEffect(() => {
    if (studentFetchData && studentFetchData.course_id) {
      const selected = coursesList.find((course) => course.value === studentFetchData.course_id);
      setSelectedCourse(selected);
    }
  }, [studentFetchData, coursesList]);

  useEffect(() => {
    if (studentFetchData && studentFetchData.name) {
      const name = `${studentFetchData.name}`;
      const parts = name.split(" ");
      let initials = "";
      for (let i = 0; i < parts.length; i++) {
        if (parts[i].length > 0 && parts[i] !== "") {
          initials += parts[i][0];
        }
      }
      setInitial(initials);

      formData.first_name = studentFetchData?.first_name;
      formData.last_name = studentFetchData?.last_name;
      formData.email = studentFetchData?.email;
      setStudentEmailExists((studentFetchData?.email != null) ?? true);
      setParentEmailExists((studentFetchData?.parentemail != null) ?? true);
      formData.phone = studentFetchData?.phone;
      formData.note = studentFetchData?.note;
      formData.studentsince = studentFetchData?.studentsince;
      formData.gender = studentFetchData?.gender;
      formData.dob = studentFetchData?.dob;
      formData.customer_number = studentFetchData?.customer_number;
      formData.special_id_number = studentFetchData?.special_id_number;
      formData.school = studentFetchData?.school;
      formData.referrer = studentFetchData?.referrer;
      formData.subjects = studentFetchData?.subjects;
      formData.skill = studentFetchData?.skill;
      formData.lessoncat = studentFetchData?.lessoncat;
      formData.lessonlen = studentFetchData?.lessonlen;
      formData.price = studentFetchData?.price;
      formData.name = studentFetchData?.name;

      setProfilePhoto(studentFetchData?.dp_url);
      setSelectedStatus(studentFetchData?.student_status);
      setSelectedStatusVal(studentFetchData?.status_label);
      setSelectedStatusColor(studentFetchData?.status_color);
      setSelectedStatusBgColor(studentFetchData?.bg_color);
      setSelectedBilling(studentFetchData?.billing);
      setSelectedAutoInvoicing(studentFetchData?.invoicing)

      formData.parentfirstname = studentFetchData?.parentfirstname;
      formData.parentlastname = studentFetchData?.parentlastname;
      formData.parentemail = studentFetchData?.parentemail;
      formData.parentmobile = studentFetchData?.parentmobile;
      formData.parentaddress = studentFetchData?.parentaddress;

      formData.parentemailpreference = studentFetchData?.parentemailpreference;
      formData.parentsmspreference = studentFetchData?.parentsmspreference;
      formData.setPreferredInvoiceRecipient = studentFetchData?.setPreferredInvoiceRecipient;
      formData.showStudentPortalContact = studentFetchData?.showStudentPortalContact;
    }
  }, [studentFetchData]);

  const handleStudyLogChange = (date) => {
    setStudyLogDate(date);
  };

  const handleAttendanceLogChange = (date) => {
    setAttendanceLogDate(date);
  };


  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleToggle = async (type) => {

    let val = '';
    if (type == 'student') {
      val = checked ? 0 : 1;
      setChecked(val === 1);
    }

    if (type == 'parent') {
      val = checkedParentAccess ? 0 : 1;
      setCheckedParentAccess(val === 1);
    }

    const validateconfig = {
      method: "POST",
      url: `${API_URL}${type}/${id}/send-login-access`,
      data: {
        enable_login_access: val,
      },
    };
    await axios(validateconfig)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePreferenceEdit = (e) => {
    setAttendFlag(!e.target.value);
    setPreferenceDisabled(false);
  };

  const handleFamilyDetailEdit = (e) => {
    setFamilyDetailFlag(!e.target.value);
    setFamilyDetailDisabled(false);
  };

  const handleCancelAttendFlag = (e) => {
    setAttendFlag(false);
    setFamilyDetailFlag(false);
    setDefaultBillingEditFlag(false);
    setDefaultAutoInvoicesEditFlag(false);
  };

  const handleCourseChangeById = (selectedOption) => {
    setSelectedCourse(selectedOption);
  };

  const handleEditChange = async (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (
      name === "first_name" ||
      name === "last_name" ||
      name === "email" ||
      name === "phone" ||
      name === "parentaddress" ||
      name === "note" ||
      name === "dob" ||
      name === "gender" ||
      name === "special_id_number" ||
      name === "customer_number" ||
      name === "school" ||
      name === "studentsince" ||
      name === "referrer" ||
      name === "subjects" ||
      name === "skill" ||
      name === "lessoncat" ||
      name === "lessonlen" ||
      name === "price"
    ) {
      setFormData({ ...formData, [name]: value });
    } else {
      if (
        name === "parentemailpreference" ||
        name === "parentsmspreference" ||
        name === "setPreferredInvoiceRecipient" ||
        name === "showStudentPortalContact"
      ) {
        if (e.target.checked) {
          value = "true";
        } else {
          value = null;
        }
      }
      setFormData({ ...formData, [name]: value });
    }

    if (name === "file") {
      console.log(e.target.files[0]);
      profilePicData["file"] = e.target.files[0];
      profilePicData["student_id"] = id;
      const config = {
        method: "POST",
        url: `${API_URL}student/update-dp`,
        headers: {
          "content-type": "multipart/form-data",
        },
        data: profilePicData,
      };
      await axios(config)
        .then((response) => {
          setProfilePhoto(response.data.data.dp_url);
          // toast.success(response.data.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });

        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.success === false) {
            setError(error.response.data.data);
          }
        });
    }
  };

  const formSubmit = async (e) => {
    formData["student_id"] = id;
    formData["student_status"] = selectedStatus;
    formData["billing"] = selectedBilling;
    formData["invoicing"] = selectedAutoInvoicing


    if (!formData.first_name) {
      setError({
        ...error,
        ['first_name']: 'First name is required'
      });
    } else if (!formData.last_name) {
      setError({
        ...error,
        ['last_name']: 'Last name is required'
      });
    } else {
      e.preventDefault();
      formData["course_id"] = selectedCourse?.value;
      const formattedData = {
        ...formData,
        dob: convertDateForSentInApi(formData.dob),
        studentsince: convertDateForSentInApi(formData.studentsince),
      };
      const config = {
        method: "PATCH",
        url: `${API_URL}update-student`,
        data: formattedData,
      };
      await axios(config)
        .then((response) => {
          // console.log(response);
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          setIsOpens(false);
          setPreferenceDisabled(true);
          setAttendFlag(false)
          setFamilyDetailDisabled(true)
          setFamilyDetailFlag(false);
          setIsNoteEdited(!isNoteEdited);
          setStudentGeneralEditFlag(false);
          setDefaultBillingEditFlag(false);
          setDefaultAutoInvoicesEditFlag(false);
          fetchStudentDetails(id);

          //toggle rushita
          if (!formData.email) {
            setStudentEmailExists(false); // Email does not exist
            setChecked(1);
            handleToggle('student');
          } else if (!formData.parentemail) {
            setParentEmailExists(false); // Email does not exist
            setCheckedParentAccess(1);
            handleToggle('parent');
          } else {
            setStudentEmailExists(true); // Email exists
            setParentEmailExists(true); // Email exists
          }

        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.success === false) {
            setError(error.response.data.data);
          }
        });
    }
  };

  function openModal(e) {
    setIsOpens(e);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal(e) {
    setIsOpens(e);
  }

  const handleChangeStatus = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "student_status") {
      setSelectedStatus(value);
      const text = e.target.getAttribute('data-status-text');
      const color = e.target.getAttribute('data-status-color');
      const bgcolor = e.target.getAttribute('data-status-bgcolor');
      setSelectedStatusVal(text);
      setSelectedStatusColor(color);
      setSelectedStatusBgColor(bgcolor);
    }
  }

  const handleStudentGeneralEdit = (e) => {
    setStudentGeneralEditFlag(true);
  }

  const handleDefaultBillingEdit = (e) => {
    setDefaultBillingEditFlag(true);
  }

  const handleAutoInvoiceEdit = (e) => {
    setDefaultAutoInvoicesEditFlag(true);
  }

  const handleBillingChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "billing") {
      setSelectedBilling(value);
    }
  }

  const handleAutoInvoiceSetup = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name == "invoicing") {
      setSelectedAutoInvoicing(value);
    }
  }

  const openEditModal = (tutorId) => {
    setIsEditMode(true); // Set edit mode
    editTutorData(tutorId); // Load tutor data for editing
  };

  const editTutorData = async (tutor_id) => {
    openModal("assignTutor"); // Open the modal
    setEditTutorId(tutor_id);  // Set the student ID to state
    const config = {
      method: "GET",
      url: `${API_URL}ts-mapping/${tutor_id}`,
    };
    await axios(config)
      .then((response) => {
        const tutorData = response.data.data
        setDefaultLessonLength(tutorData.default_lesson_length);
        setDefaultLessonCat(tutorData.default_lesson_cat);
        setDefaultBilling(tutorData.default_billing);
        setMakeUpCredits(tutorData.make_up_credits)
        if (tutorData.default_billing == "per_lesson_charge") {
          setPrice(tutorData.per_lesson_charge || "0.00");
        } else if (tutorData.default_billing == "per_month_charge") {
          setPrice(tutorData.per_month_charge || "0.00");
        } else if (tutorData.default_billing == "per_hour_charge") {
          setPrice(tutorData.per_hour_charge || "0.00");
        } else {
          setPrice("0.00");
        }
        setTutorId(tutorData.tutor_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const updateTutorData = async (e) => {
    e.preventDefault();
    let selectedTutor = document.getElementById("tutor_id");

    assignTutor["student_id"] = id;
    assignTutor["tutor_id"] = selectedTutor?.value;

    if (defaultBilling === "per_lesson_charge") {
      assignTutor["per_lesson_charge"] = price;
    }

    if (defaultBilling === "per_month_charge") {
      assignTutor["per_month_charge"] = price;
    }

    if (defaultBilling === "per_hour_charge") {
      assignTutor["per_hour_charge"] = price;
    }

    if (!assignTutor.hasOwnProperty("default_lesson_cat")) {
      assignTutor["default_lesson_cat"] = defaultLessonCat;
    }

    if (!assignTutor.hasOwnProperty("make_up_credits")) {
      assignTutor["make_up_credits"] = makeUpCredits;
    }

    if (!assignTutor.hasOwnProperty("default_lesson_length")) {
      assignTutor["default_lesson_length"] = defaultLessonLength;
    }

    const config = {
      method: "PATCH",
      url: `${API_URL}update-ts-mapping/${editTutorId}`,
      data: assignTutor,
    };
    await axios(config)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setIsOpen(false);
        closeModal();
        fetchAssignTutors(id);
        setAssignTutor({});
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.success === false) {
          setError(error.response.data.data);
        }
        setAssignTutor({});
      });
  }

  const deleteTutor = async (tutor_id) => {
    const config = {
      method: "DELETE",
      url: `${API_URL}delete-ts-mapping/${tutor_id}`,
    };
    await axios(config)
      .then((response) => {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        fetchAssignTutors(id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="wrapper student-details">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />

        <ReactModal
          isOpen={modalIsOpen === "profile"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Profile Modal"
        >
          <div className="mypreference-modal">
            <div className="close-h">
              <h4>Edit Profile</h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <form name="studentProfile">
              <div className="row d-flex">
                <div className="col-xl-4 col-xxl-4">
                  <div className="formbold-input-flex justify-content-center">
                    <div className="student-profile-view">
                      <label htmlFor="file" className="formbold-form-label">
                        Photo <span>Optional</span>
                      </label>
                      <div className="initials py-3">
                        <div className="image-user">
                          {profilePhoto ? (
                            <>
                              <img src={profilePhoto} alt="" />
                            </>
                          ) : (
                            <h2>{initial && initial.toLocaleUpperCase()}</h2>
                          )}
                        </div>
                      </div>
                      <input
                        type="file"
                        name="file"
                        className="form-control b-none"
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-xxl-8">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="first_name"
                          className="formbold-form-label"
                          id="first_name"
                        >
                          First name
                        </label>
                        <input
                          type="text"
                          name="first_name"
                          className="form-control"
                          value={formData.first_name}
                          onChange={handleEditChange}
                          required
                        />
                        <div className="pt-2">
                          <small className="input-error-message">
                            {error?.first_name?.length ? error.first_name : <></>}
                          </small>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="last_name"
                          className="formbold-form-label"
                          id="last_name"
                        >
                          Last name
                        </label>
                        <input
                          type="text"
                          name="last_name"
                          className="form-control"
                          value={formData.last_name}
                          onChange={handleEditChange}
                          required
                        />
                        <div className="pt-2">
                          <small className="input-error-message">
                            {error?.last_name?.length ? error.last_name : <></>}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="email"
                          className="formbold-form-label"
                          id="email"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={formData.email}
                          onChange={handleEditChange}
                          required
                        />
                        <div className="pt-2">
                          <small className="input-error-message">
                            {error?.email?.length ? error.email : <></>}
                          </small>
                        </div>
                      </div>
                      <div>
                        <div>
                          <label
                            htmlFor="phone"
                            className="formbold-form-label"
                            id="phone"
                          >
                            Phone Number <span>Optional</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleEditChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div>
                        <div>
                          <label
                            htmlFor="phone"
                            className="formbold-form-label"
                            id="phone"
                          >
                            Courses
                          </label>
                          <Select
                            value={selectedCourse}
                            onChange={handleCourseChangeById}
                            options={coursesList}
                            styles={colourStyles}
                          />
                        </div>
                      </div>
                    <div className="formbold-input-flex diff">
                      <div>
                        <div>
                          <label
                            htmlFor="student_status"
                            className="formbold-form-label"
                          >
                            Student Status
                          </label>
                        </div>
                        <div className="studentStatus">
                          {statusList.map((status) => {
                            return (
                              <label className="student-status">
                                <input
                                  type="radio"
                                  className="status"
                                  name="student_status"
                                  onChange={handleChangeStatus}
                                  value={status.id}
                                  checked={selectedStatus == status.id}
                                  data-status-text={status.status_title}
                                  data-status-color={status.status_color}
                                  data-status-bgcolor={status.bg_color}
                                />
                                <span style={{ color: status.status_color, backgroundColor: status.bg_color }}> {status.status_title} </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel" onClick={closeModal}>
                      Cancel
                    </Link>

                    <button className="formbold-btn" onClick={formSubmit}>Save</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>
        <ReactModal
          isOpen={modalIsOpen === "assignTutor"}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="mypreference-modal">
            <div className="close-h">
              <h4>{isEditMode ? "Edit Tutor" : "Assign Tutor"}</h4>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            <form name="studentProfile">
              <div className="row d-flex">
                <div className="col-xl-12 col-xxl-12">
                  <div className="formbold-form-step-1 active">
                    <div className="formbold-input-flex diff">
                      <div>
                        <label
                          htmlFor="tutor_id"
                          className="formbold-form-label"
                        >
                          Tutor
                        </label>
                        <div>
                          <select
                            name="tutor_id"
                            className="form-control"
                            onChange={handleAssignTutor}
                            id="tutor_id"
                            value={tutorId}
                          >
                            {allTutors &&
                              allTutors.map((tutor) => {
                                return (
                                  <option key={tutor.id} value={tutor.id}>
                                    {tutor.name ? tutor.name : ""}
                                  </option>
                                );
                              })}
                          </select>
                          <div className="pt-2">
                            <small className="input-error-message">
                              {error?.error?.length ? error.error : <></>}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formbold-input-flex">
                      <input type="hidden" name="editTutorId" value={editTutorId} />
                    </div>
                    <div className="formbold-input-flex">
                      <div>
                        <label
                          htmlFor="default_lesson_cat"
                          className="formbold-form-label"
                        >
                          Default Lesson Category
                        </label>
                        <br></br>

                        <select
                          name="default_lesson_cat"
                          className="form-control"
                          onChange={handleAssignTutor}
                          id="default_lesson_cat"
                          value={defaultLessonCat}
                        >
                          <option value="">Select a category</option>
                          {allCategory &&
                            allCategory.map((cat) => {
                              return (
                                <option key={cat.id} value={cat.id}>
                                  {cat.eventcat_name
                                    ? cat.eventcat_name
                                    : "Unknown Category"}
                                </option>
                              );
                            })}
                        </select>
                        <div className="pt-2">
                          <small className="input-error-message">
                            {error?.default_lesson_cat?.length ? (
                              error.default_lesson_cat[0]
                            ) : (
                              <></>
                            )}
                          </small>
                        </div>
                      </div>
                      <div>
                        <label
                          htmlFor="default_lesson_length"
                          className="formbold-form-label"
                          id="default_lesson_length"
                        >
                          Default Lesson Length
                        </label>
                        <div style={{ position: "relative" }}>
                          <input
                            type="text"
                            name="default_lesson_length"
                            className="form-control"
                            onChange={handleAssignTutor}
                            value={defaultLessonLength}
                            required
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            minutes
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="formbold-input-flex diff">
                      <div>
                        <div>
                          <label
                            htmlFor="default_billing"
                            className="formbold-form-label"
                          >
                            Default Billing
                          </label>
                        </div>
                        <div className="input-radio">
                          <label>
                          <input
                            type="radio"
                            value="no_automatic_charge"
                            name="default_billing"
                            onChange={handleAssignTutor}
                            checked={defaultBilling === "no_automatic_charge"}
                          ></input>
                          Don't automatically create any calendar-generated
                          charges
                          </label>
                        </div>
                        <div className="input-radio">
                        <label>
                          <input
                            type="radio"
                            value="per_lesson_charge"
                            name="default_billing"
                            onChange={handleAssignTutor}
                            checked={defaultBilling === "per_lesson_charge"}
                          ></input>
                          Student pays based on the number of lessons taken
                          </label>
                        </div>
                        <div className="input-radio">
                        <label>
                          <input
                            type="radio"
                            value="per_month_charge"
                            name="default_billing"
                            onChange={handleAssignTutor}
                            checked={defaultBilling === "per_month_charge"}
                          ></input>
                          Student pays the same amount each month regardless of
                          number of lessons
                          </label>
                        </div>
                        <div className="input-radio">
                        <label>
                          <input
                            type="radio"
                            value="per_hour_charge"
                            name="default_billing"
                            onChange={handleAssignTutor}
                            checked={defaultBilling === "per_hour_charge"}
                          ></input>
                          Student pays an hourly rate
                          </label>
                        </div>
                        <span>
                          Charges will automatically adjust to lesson length
                        </span>
                      </div>
                    </div>

                    <div className="formbold-input-flex">
                      {defaultBilling !== "no_automatic_charge" && (
                        <div>
                          <div>
                            <label
                              htmlFor="price"
                              className="formbold-form-label"
                              id="price"
                            >
                              Price
                            </label>
                            <div style={{ position: "relative" }}>
                              <span
                                style={{
                                  position: "absolute",
                                  left: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                {selectedCurrency}
                              </span>
                              <input
                                type="text"
                                name="price"
                                className="form-control"
                                style={{
                                  paddingLeft: "25px",
                                  paddingRight: "70px",
                                }}
                                onChange={handleAssignTutor}
                                value={price}
                                required
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                {defaultBilling === "per_lesson_charge" &&
                                  "Per Lesson"}
                                {defaultBilling === "per_month_charge" &&
                                  "Per Month"}
                                {defaultBilling === "per_hour_charge" &&
                                  "Per Hour"}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="formbold-input-flex diff">
                        <div>
                          <label
                            htmlFor="make_up_credits"
                            className="formbold-form-label"
                            id="make_up_credits"
                          >
                            Make-Up Credits
                          </label>
                          <div style={{ position: "relative" }}>
                            <input
                              type="text"
                              name="make_up_credits"
                              className="form-control"
                              onChange={handleAssignTutor}
                              value={makeUpCredits}
                              required
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "50%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              Credits
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="formbold-form-btn-wrapper">
                  <div className="btn-end">
                    <Link className="cancel"
                      onClick={() => {
                        closeModal();
                        setIsEditMode(false);
                      }}
                    >
                      Cancel
                    </Link>
                    {isEditMode ? (
                      <button className="formbold-btn" onClick={updateTutorData}>
                        Save
                      </button>
                    ) : (
                      <button className="formbold-btn" onClick={saveAssignTutor}>
                        Assign
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </ReactModal>
        <main className="content">
          <ToastContainer />
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-4 col-xxl-4">
                {hasEditAccess &&
                  <div className="row">
                    <div className="col-md-12">
                      <Select
                        className="student-dropdown pb-1"
                        value={selectedStudent}
                        onChange={onChangeSelectStudentHandler}
                        isMulti={false}
                        options={[
                          ...allStudents.map((e) => {
                            return { label: e.name, value: e.id };
                          }),
                        ]}
                      />
                    </div>
                  </div>
                }
                <div className="card">
                  <div className="card-body">
                    <div className="initials">
                      <div className="image-user">
                        {profilePhoto ? (
                          <>
                            <img src={profilePhoto} alt="" />
                          </>
                        ) : (
                          <h2>{initial && initial.toLocaleUpperCase()}</h2>
                        )}
                      </div>
                    </div>
                    <div
                      className="edit-user"
                      onClick={(e) => openModal("profile")}
                    >
                      <i className="fa fa-pencil" aria-hidden="true"></i>
                    </div>

                    <div className="title-user">
                      {studentFetchData?.name}
                      <br></br>
                      {studentFetchData?.studentType &&
                        <span className="student-type-span">[ {studentFetchData?.studentType} ]</span>}
                    </div>
                    {studentFetchData?.student_status && (
                      <>
                        <div className="studentstatus-wrapper">
                          <div className="student-status">
                            <span style={{ color: selectedStatusColor, backgroundColor: selectedStatusBgColor }}>
                              {selectedStatusVal}</span>
                          </div>
                        </div>
                      </>
                    )}

                    <div className="link-to-family">
                      <Link to={"/families-and-invoices/family/" + studentFetchData?.family_account_id}>View Family Account</Link>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="arrange-edit-sign">
                      <h3>Notes</h3>
                      <div className="student-edit-user" onClick={(e) => setIsNoteEdited(!isNoteEdited)}>
                        {isNoteEdited ?
                          <i className="fa fa-close" aria-hidden="true"></i>
                          :
                          <i className="fa fa-pencil" aria-hidden="true"></i>
                        }
                      </div>
                    </div>
                    {isNoteEdited ?

                      <div>
                        <input type="text" className="form-control mb-3" value={formData?.note} name="note" onChange={handleEditChange}></input>
                        <div className="btn-end flex justify-content-end">
                          <button className="formbold-btn" onClick={formSubmit}>Save</button>
                        </div>
                      </div>
                      :
                      <span>
                        {formData?.note}
                      </span>
                    }

                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="arrange-edit-sign">
                      <h3>Attachments</h3>
                      {hasEditAccess &&
                        <div className="student-edit-user add">
                          <i className="fa fa-plus" aria-hidden="true"></i> Add
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-xxl-8">

                {/* General Details */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingEight">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEight"
                          aria-expanded="false"
                          aria-controls="flush-collapseEight"
                        >
                          <strong>General Details</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseEight"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingEight"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            <h3>General Details</h3>
                            {hasEditAccess &&
                              <div className="student-edit-user" onClick={handleStudentGeneralEdit}>
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            }
                          </div>
                          <div className={studentGeneralEditFlag ? "" : "disabled-form"}>
                            <div className="formbold-input-flex">
                              <div>
                                <label htmlFor="gender" className="formbold-form-label">
                                  Gender <span>Optional</span>
                                </label>
                                <select
                                  name="gender"
                                  className="form-control"
                                  value={formData?.gender}
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                  <option value="other">Other</option>
                                  <option value="prefer_not_to_say">Prefer not to say</option>
                                </select>
                              </div>
                              <div>
                                <label
                                  htmlFor="dob"
                                  className="formbold-form-label"
                                >
                                  Date of Birth <span>Optional</span>
                                </label>
                                <IconDatePicker
                                  selectedDate={formData?.dob}
                                  onChange={(date) => setFormData({ ...formData, ['dob']: date })}
                                  minDate={null}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="customer_number"
                                  className="formbold-form-label"
                                >
                                  Customer Number <span>Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="customer_number"
                                  value={formData?.customer_number}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="special_id_number"
                                  className="formbold-form-label"
                                >
                                  Special Id Number <span>Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="special_id_number"
                                  value={formData?.special_id_number}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="school"
                                  className="formbold-form-label"
                                >
                                  School <span>Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="school"
                                  value={formData?.school}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="studentsince"
                                  className="formbold-form-label"
                                >
                                  Student Since <span>Optional</span>
                                </label>
                                <IconDatePicker
                                  selectedDate={formData?.studentsince}
                                  onChange={(date) => setFormData({ ...formData, ['studentsince']: date })}
                                  minDate={null}
                                />
                              </div>
                              <div>
                                <label
                                  htmlFor="referrer"
                                  className="formbold-form-label"
                                >
                                  Referrer <span>Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="referrer"
                                  value={formData?.referrer}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex diff">
                              <div>
                                <label
                                  htmlFor="subjects"
                                  className="formbold-form-label"
                                >
                                  Subjects <span>Optional</span>
                                </label>
                                <br></br>
                                <small>
                                  Use a semicolon or press the Enter key to
                                  separate entries
                                </small>
                                <input
                                  type="text"
                                  name="subjects"
                                  value={formData?.subjects}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="skill"
                                  className="formbold-form-label"
                                >
                                  Skill Level <span>Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="skill"
                                  value={formData?.skill}
                                  className="form-control"
                                  onChange={handleEditChange}
                                  disabled={!studentGeneralEditFlag}
                                />
                              </div>
                            </div>
                          </div>
                          {studentGeneralEditFlag && (
                            <>
                              <div className="formbold-form-btn-wrapper justify-content-end">
                                <div className="btn-end">
                                  <Link
                                    className="cancel"
                                    onClick={handleCancelAttendFlag}
                                  >
                                    Cancel
                                  </Link>

                                  <button
                                    className="formbold-btn"
                                    onClick={formSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Student Overview  */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingOne">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseOne"
                          aria-expanded="false"
                          aria-controls="flush-collapseOne"
                        >
                          <strong>Student Overview</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseOne"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            <h3>Student Overview</h3>
                            {hasEditAccess &&
                              <div className="student-edit-user" onClick={handlePreferenceEdit}>
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            }
                          </div>
                          <div className={attendFlag ? "" : "disabled-form"}>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="preferences"
                                  className="formbold-form-label"
                                >
                                  Preferences
                                </label>
                                <br></br>
                                <label className="preference">
                                  <input type="checkbox"
                                    name="parentemailpreference"
                                    disabled={preferenceDisabled}
                                    onChange={handleEditChange}
                                    checked={
                                      formData?.parentemailpreference !== null
                                        ? true
                                        : false
                                    }
                                  />
                                  Send email lesson reminders
                                </label>
                                <label className="preference">
                                  <input type="checkbox"
                                    name="parentsmspreference"
                                    disabled={preferenceDisabled}
                                    onChange={handleEditChange}
                                    checked={
                                      formData?.parentsmspreference !== null
                                        ? true
                                        : false
                                    }
                                  />
                                  Send SMS lesson reminders
                                </label>
                                <label className="preference">
                                  <input type="checkbox"
                                    name="setPreferredInvoiceRecipient"
                                    disabled={preferenceDisabled}
                                    onChange={handleEditChange}
                                    checked={
                                      formData?.setPreferredInvoiceRecipient !== null
                                        ? true
                                        : false
                                    }
                                  />
                                  Set as the preferred invoice recipient
                                </label>
                                <label className="preference">
                                  <input type="checkbox"
                                    name="showStudentPortalContact"
                                    disabled={preferenceDisabled}
                                    onChange={handleEditChange}
                                    checked={
                                      formData?.showStudentPortalContact !== null
                                        ? true
                                        : false
                                    }
                                  />
                                  Show in Student Portal contacts
                                </label>
                                <span style={{ paddingLeft: "23px" }}>
                                  Will only be sent if SMS messaging is allowed
                                </span>
                              </div>
                            </div>
                          </div>
                          {attendFlag && (
                            <>
                              <div className="formbold-form-btn-wrapper justify-content-end">
                                <div className="btn-end">
                                  <Link
                                    className="cancel"
                                    onClick={handleCancelAttendFlag}
                                  >
                                    Cancel
                                  </Link>

                                  <button
                                    className="formbold-btn"
                                    onClick={formSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Parent Detail */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTwo"
                          aria-expanded="false"
                          aria-controls="flush-collapseTwo"
                        >
                          <strong>Parent Details</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            {studentFetchData?.parentfirstname !== null &&
                              studentFetchData?.parentlastname !== null ? (
                              <>
                                <h3>
                                  {`${studentFetchData?.parentfirstname}, ${studentFetchData?.parentlastname}`}
                                </h3>
                              </>
                            ) : (
                              <>
                                <strong>Parents Details Not Exists</strong>
                              </>
                            )}

                            <div className="student-edit-user" onClick={handleFamilyDetailEdit}>
                              <i
                                className="fa fa-pencil"
                                aria-hidden="true"
                              ></i>
                            </div>
                          </div>
                          <div className={familyDetailFlag ? "" : "disabled-form"}>
                            <div className="formbold-input-flex ">
                              <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                  <div className="formbold-form-step-1 active">
                                    <div>
                                      <label
                                        htmlFor="parentfirstname"
                                        className="formbold-form-label"
                                        id="parentfirstname"
                                      >
                                        First name
                                      </label>
                                      <input
                                        type="text"
                                        name="parentfirstname"
                                        className="form-control"
                                        value={formData.parentfirstname}
                                        onChange={handleEditChange}
                                        disabled={familyDetailDisabled}
                                        required
                                      />
                                    </div>
                                    <div className="pt-2">
                                      <label
                                        htmlFor="parentlastname"
                                        className="formbold-form-label"
                                        id="parentlastname"
                                      >
                                        Last name
                                      </label>
                                      <input
                                        type="text"
                                        name="parentlastname"
                                        className="form-control"
                                        value={formData.parentlastname}
                                        onChange={handleEditChange}
                                        disabled={familyDetailDisabled}
                                        required
                                      />
                                    </div>
                                    <div className="pt-2">
                                      <label
                                        htmlFor="parentemail"
                                        className="formbold-form-label"
                                        id="parentemail"
                                      >
                                        Email Address
                                      </label>
                                      <input
                                        type="parentemail"
                                        name="parentemail"
                                        className="form-control"
                                        value={formData.parentemail}
                                        onChange={handleEditChange}
                                        disabled={familyDetailDisabled}
                                        required
                                      />
                                    </div>
                                    <div className="pt-2">
                                      <div>
                                        <label
                                          htmlFor="parentmobile"
                                          className="formbold-form-label"
                                          id="parentmobile"
                                        >
                                          Phone Number <span>Optional</span>
                                        </label>
                                        <input
                                          type="text"
                                          name="parentmobile"
                                          className="form-control"
                                          value={formData.parentmobile}
                                          disabled={familyDetailDisabled}
                                          onChange={handleEditChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="pt-2">
                                      <label
                                        htmlFor="parentaddress"
                                        className="formbold-form-label"
                                      >
                                        Address <span>Optional</span>
                                      </label>
                                      <br></br>

                                      <textarea
                                        name="parentaddress"
                                        className="form-control"
                                        value={formData.parentaddress}
                                        disabled={familyDetailDisabled}
                                        onChange={handleEditChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {familyDetailFlag && (
                            <>
                              <hr></hr>
                              <div className="formbold-form-btn-wrapper justify-content-end">
                                <div className="btn-end">
                                  <Link
                                    className="cancel"
                                    onClick={handleCancelAttendFlag}
                                  >
                                    Cancel
                                  </Link>

                                  <button
                                    className="formbold-btn"
                                    onClick={formSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {/* <div className="formbold-form-btn-wrapper">
                            <div className="btn-end">
                              <Link className="cancel" to="/students">
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                ></i>
                                Change Family
                              </Link>

                              <button className="formbold-btn">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                Add Another Contact
                              </button>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Family Contacts */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingNine">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseNine"
                          aria-expanded="false"
                          aria-controls="flush-collapseNine"
                        >
                          <strong>Family Contacts</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseNine"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingNine"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <FetchStudentFamilyContactDatatable userId={id} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Assigned Tutor */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseThree"
                          aria-expanded="false"
                          aria-controls="flush-collapseThree"
                        >
                          <strong>Assigned Tutors</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingThree"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <h3>Assigned Tutors</h3>
                          {tutors.length > 0 ? (
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Name</th>
                                  <th>Default Price</th>
                                  <th>Default Lesson Length</th>
                                  <th>Default Lesson Category</th>
                                  <th>Make-Up Credits</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {tutors.map((tutor) => (
                                  <tr key={tutor.tutor_id}>
                                    <td>
                                      <Link
                                        to={`/tutors/details/${tutor.tutor_id}`}
                                      >
                                        {getTutorById(tutor?.tutor_id)?.name}
                                      </Link>
                                    </td>
                                    <td>
                                      {tutor.default_billing ===
                                        "per_month_charge" && (
                                          <>
                                            {selectedCurrency}{" "}
                                            {tutor.per_month_charge}/month
                                          </>
                                        )}

                                      {tutor.default_billing ===
                                        "per_lesson_charge" && (
                                          <>
                                            {selectedCurrency}{" "}
                                            {tutor.per_lesson_charge}/lesson
                                          </>
                                        )}

                                      {tutor.default_billing ===
                                        "per_hour_charge" && (
                                          <>
                                            {selectedCurrency}{" "}
                                            {tutor.per_hour_charge}/hour
                                          </>
                                        )}

                                      {tutor.default_billing !==
                                        "per_month_charge" &&
                                        tutor.default_billing !==
                                        "per_lesson_charge" &&
                                        tutor.default_billing !==
                                        "per_hour_charge" && (
                                          <p>Default price not specified</p>
                                        )}
                                    </td>
                                    <td>{tutor.default_lesson_length}</td>
                                    <td>
                                      {
                                        allCategory.find(
                                          (cat) =>
                                            cat.id === tutor.default_lesson_cat
                                        )?.eventcat_name
                                      }
                                    </td>
                                    <td>
                                      Total: {tutor.makeup_credits} <br />
                                      Booked: {tutor.makeup_credits} <br />
                                      Available: {tutor.makeup_credits} <br />
                                    </td>
                                    <td>
                                      {/* Edit icon */}
                                      {hasEditAccess &&
                                        <div className="d-flex gap-2">
                                          <i
                                            className="fa fa-pencil"
                                            aria-hidden="true"
                                            onClick={() => openEditModal(tutor.id)}
                                          ></i>
                                          {/* Delete icon */}
                                          <i
                                            className="fa fa-trash"
                                            aria-hidden="true"
                                            onClick={() => {
                                              deleteTutor(tutor.id);
                                            }}
                                          ></i>
                                        </div>
                                      }
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          ) : (
                            <div className="row">
                              <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                                <div className="flex-fill w-100">
                                  <div className="py-3">
                                    <div className="chart chart-xs">
                                      <img src={instructors}></img>
                                    </div>
                                  </div>
                                  <h5>
                                    <strong>
                                      There aren't any tutors assigned to this
                                      student
                                    </strong>
                                  </h5>
                                  <hr></hr>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="formbold-form-btn-wrapper">
                            <div className="btn-end">
                              <button
                                className="formbold-btn"
                                onClick={(e) => openModal("assignTutor")}
                              >
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                Assign Tutor
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Study Logs */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFour">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFour"
                          aria-expanded="false"
                          aria-controls="flush-collapseFour"
                        >
                          <strong>Study Log</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFour"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFour"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="calculate-study">
                            <div>
                              <div className="days-studied">
                                <i
                                  className="fa fa-calendar"
                                  aria-hidden="true"
                                ></i>{" "}
                                <h5>Days Studied</h5>
                              </div>
                              <span>
                                1 day this week <br></br>1 day in the last 30
                                days
                              </span>
                            </div>
                            <div>
                              <div className="hours-studied">
                                <i
                                  className="fa fa-clock"
                                  aria-hidden="true"
                                ></i>{" "}
                                <h5>Hours Studied</h5>
                              </div>
                              <span>
                                0:10 hours this week{" "}
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>{" "}
                                <emp>100%</emp>
                                <br></br>0:10 hours in the last 30 days
                              </span>
                            </div>
                            <div>
                              <div className="average-study">
                                <i
                                  className="fa fa-bar-chart"
                                  aria-hidden="true"
                                ></i>{" "}
                                <h5>Average Study Time</h5>
                              </div>
                              <span>
                                0:10 hours this week{" "}
                                <i
                                  className="fa fa-long-arrow-up"
                                  aria-hidden="true"
                                ></i>{" "}
                                <emp>100%</emp>
                                <br></br>0:10 hours in the last 30 days
                              </span>
                            </div>
                            <div className="student-edit-user">
                              <i className="fa fa-cog" aria-hidden="true"></i>
                            </div>
                          </div>
                          <div className="calendar-body">
                            <h5>
                              {studentFetchData?.first_name}'s Study Log as of{" "}
                              <div className="custom-datepicker-container">
                                <DatePicker
                                  selected={studyLogDate}
                                  onChange={handleStudyLogChange}
                                  dateFormat={settings.date_format}
                                  className="custom_datepicker"
                                />
                                <span className="down-arrow">&#9660;</span>
                              </div>
                            </h5>
                            <a className="addnew" href="#" role="button">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              Add Time
                            </a>

                            <FetchStudyLog />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Attendance and Notes */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingFive">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseFive"
                          aria-expanded="false"
                          aria-controls="flush-collapseFive"
                        >
                          <strong>Attendence and Notes</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseFive"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingFive"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            <h3>Average attendance for last 90 days</h3>
                          </div>
                          <div className="avg-attend">
                            <div className="avg-data">
                              <span>{studentAttendanceSummery.presentCount}</span>
                              <span>Present</span>
                            </div>
                            <div className="avg-data">
                              <span>{studentAttendanceSummery.unrecordedCount}</span>
                              <span>Unrecorded</span>
                            </div>
                            <div className="avg-data">
                              <span>{studentAttendanceSummery.absentCount}</span>
                              <span>Student Absences</span>
                            </div>
                            <div className="avg-data">
                              <span>{studentAttendanceSummery.tutorCanceledCount}</span>
                              <span>Canceled Events</span>
                            </div>
                            <div className="avg-data">
                              <span>{studentAttendanceSummery.totalEvents}</span>
                              <span>Total Events</span>
                            </div>
                          </div>
                          <div className="formbold-form-btn-wrapper">
                            {hasEditAccess &&
                              <div className="btn-end">
                                <button className="formbold-btn">
                                  <i
                                    className="fa fa-plus"
                                    aria-hidden="true"
                                  ></i>
                                  Create Report
                                </button>
                              </div>
                            }
                          </div>
                          <div className="calendar-body">
                            <h5>
                              {studentFetchData?.first_name}'s attendance
                              records as of{" "}
                              <div className="custom-datepicker-container">
                                <DatePicker
                                  selected={attendanceLogDate}
                                  onChange={handleAttendanceLogChange}
                                  dateFormat={settings.date_format}
                                  className="custom_datepicker"
                                />
                                <span className="down-arrow">&#9660;</span>
                              </div>
                            </h5>

                            <FetchAttendanceLog userId={id} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Default billing settings */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseTen"
                          aria-expanded="false"
                          aria-controls="flush-collapseTen"
                        >
                          <strong>Default Billing Setup</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseTen"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            <h3>Default Billing Setup</h3>
                            {hasEditAccess &&
                              <div className="student-edit-user" onClick={handleDefaultBillingEdit}>
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            }
                          </div>
                          <div className={defaultBillingEditFlag ? "" : "disabled-form"}>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="lessoncat"
                                  className="formbold-form-label"
                                >
                                  Default Lesson Category <span> Optional</span>
                                </label>
                                <select
                                  name="lessoncat"
                                  className="form-control"
                                  value={formData?.lessoncat}
                                  onChange={handleEditChange}
                                  disabled={!defaultBillingEditFlag}
                                >
                                  <option>Group Lesson</option>
                                  <option>Lesson</option>
                                  <option>Vacation</option>
                                </select>
                              </div>
                              <div>
                                <label
                                  htmlFor="lessonlen"
                                  className="formbold-form-label"
                                >
                                  Default Lesson Length <span> Optional</span>
                                </label>
                                <input
                                  type="text"
                                  name="lessonlen"
                                  className="form-control"
                                  value={formData?.lessonlen}
                                  onChange={handleEditChange}
                                  disabled={!defaultBillingEditFlag}
                                />
                              </div>
                            </div>
                            <div className="formbold-input-flex diff">
                              <div>
                                <label
                                  htmlFor="billing"
                                  className="formbold-form-label"
                                >
                                  Default Billing
                                </label>
                                <br></br>
                                <div className="preference">
                                <label>
                                  <input
                                    type="radio"
                                    name="billing"
                                    value="no_automatic_charge"
                                    onChange={handleBillingChange}
                                    checked={selectedBilling == "no_automatic_charge"}
                                  />
                                  Don't automatically create any calendar-generated
                                  charges
                                  </label>
                                </div>
                                <div className="preference">
                                <label>
                                  <input
                                    type="radio"
                                    name="billing"
                                    value="per_lesson_charge"
                                    onChange={handleBillingChange}
                                    checked={selectedBilling == "per_lesson_charge"}
                                  />
                                  Student pays based on the number of lessons taken
                                  </label>
                                </div>
                                <div className="preference">
                                <label>
                                  <input
                                    type="radio"
                                    name="billing"
                                    value="per_month_charge"
                                    onChange={handleBillingChange}
                                    checked={selectedBilling == "per_month_charge"}

                                  />
                                  Student pays the same amount each month regardless
                                  of number of lessons
                                  </label>
                                </div>
                                <div className="preference">
                                <label>
                                  <input
                                    type="radio"
                                    name="billing"
                                    value="per_hour_charge"
                                    onChange={handleBillingChange}
                                    checked={selectedBilling == "per_hour_charge"}
                                  />
                                  Student pays an hourly rate
                                  </label>
                                </div>
                                <span>
                                  Charges will automatically adjust to lesson length
                                </span>
                              </div>
                            </div>
                            
                            <div className="formbold-input">
                              {selectedBilling !== "no_automatic_charge" && (
                                <>
                                  <label htmlFor="price" className="formbold-form-label">
                                    Price
                                  </label>
                                  <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text" style={{ height: '100%' }}>
                                        {selectedCurrency}
                                      </span>
                                    </div>
                                    <input
                                      type="number"
                                      name="price"
                                      className="form-control"
                                      value={formData?.price}
                                      onChange={handleEditChange}
                                      disabled={!defaultBillingEditFlag}
                                    />
                                    <div className="input-group-append">
                                      <span className="input-group-text" style={{ height: '100%' }}>
                                        {selectedBilling === "per_lesson_charge" &&
                                          "Per Lesson"}
                                        {selectedBilling === "per_month_charge" &&
                                          "Per Month"}
                                        {selectedBilling === "per_hour_charge" &&
                                          "Per Hour"}</span>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>

                          </div>
                          {defaultBillingEditFlag && (
                            <>
                              <div className="formbold-form-btn-wrapper justify-content-end">
                                <div className="btn-end">
                                  <Link
                                    className="cancel"
                                    onClick={handleCancelAttendFlag}
                                  >
                                    Cancel
                                  </Link>

                                  <button
                                    className="formbold-btn"
                                    onClick={formSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Message History */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingSix">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseSix"
                          aria-expanded="false"
                          aria-controls="flush-collapseSix"
                        >
                          <strong>Message History</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseSix"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingSix"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="col-xl-12 col-xxl-12">
                            <h5>
                              <strong>Messages from the </strong>
                              <select className="mes-history-drop">
                                <option>Last 3 Months</option>
                                <option>Last 6 Months</option>
                                <option>Last Year</option>
                                <option>Entire History</option>
                                <option>Scheduled Queue (Upcoming)</option>
                              </select>
                            </h5>
                          </div>
                          <div className="col-xl-12 col-xxl-12">
                            <div className="tab-content" id="myTabContent">
                              <div
                                className="tab-pane fade show active"
                                id="home"
                                role="tabpanel"
                                aria-labelledby="home-tab"
                              >
                                <div className="row">
                                  <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                                    <div className="flex-fill w-100">
                                      <div className="card-body d-flex">
                                        <div className="align-self-center w-100">
                                          <div className="py-3">
                                            <div className="chart chart-xs">
                                              <img
                                                src={lending}
                                                alt="lending"
                                              ></img>
                                            </div>
                                          </div>
                                          <h5 style={{ textAlign: "center" }}>
                                            <strong>
                                              You haven't sent any emails or SMS
                                              messages during this time
                                            </strong>
                                          </h5>
                                          <div class="position-set">
                                            <div className="add-message">
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              ></i>
                                              <Link
                                                className="btn"
                                                role="button"
                                              >
                                                Create New Message
                                              </Link>
                                              <i
                                                class="fa fa-caret-down"
                                                aria-hidden="true"
                                              ></i>
                                            </div>
                                            {/* {createMessage && (
                                              <>
                                                <div
                                                  className="create-mes dropdown-menu addNewDropdown"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <Link
                                                    className="dropdown-item"
                                                    to="/students"
                                                  >
                                                    <i
                                                      class="fa fa-envelope"
                                                      aria-hidden="true"
                                                    ></i>
                                                    Email Student
                                                  </Link>
                                                  <div className="dropdown-divider"></div>
                                                  <Link
                                                    className="dropdown-item"
                                                    to="/students/message-history"
                                                  >
                                                    <i
                                                      class="fa fa-envelope"
                                                      aria-hidden="true"
                                                    ></i>
                                                    Email All{" "}
                                                  </Link>
                                                </div>
                                              </>
                                            )} */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Automatic Invoice setup */}
                <div className="card">
                  <div
                    className="accordion accordion-flush"
                    id="accordionFlushExample"
                  >
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="flush-headingEleven">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#flush-collapseEleven"
                          aria-expanded="false"
                          aria-controls="flush-collapseEleven"
                        >
                          <strong>Automatic Invoicing Setup</strong>
                        </button>
                      </h2>
                      <div
                        id="flush-collapseEleven"
                        className="accordion-collapse collapse"
                        aria-labelledby="flush-headingEleven"
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">
                          <div className="student-properties-edit">
                            <h3>Automatic Invoice Setup</h3>
                            {hasEditAccess &&
                              <div className="student-edit-user" onClick={handleAutoInvoiceEdit}>
                                <i
                                  className="fa fa-pencil"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            }
                          </div>
                          <div className={defaultAutoInvoicesEditFlag ? "" : "disabled-form"}>
                            <div className="formbold-input-flex">
                              <div>
                                <label
                                  htmlFor="lessoncat"
                                  className="formbold-form-label"
                                >
                                  Set Up Automatic Invoicing
                                </label>
                                <p className="preference">
                                  You can set up automatic invoicing now, or you can set it up later in the family account</p>
                                <div className="formbold-input">
                                  <div>
                                    <div>
                                      <label htmlFor="invoicing" className="formbold-form-label">
                                        Would you like to set up automatic invoicing for this family now?
                                      </label>
                                    </div>
                                    <div className="invoicing">
                                      <label>
                                        <input
                                          type="radio"
                                          className="status"
                                          name="invoicing"
                                          value="yes"
                                          onChange={handleAutoInvoiceSetup}
                                          checked={selectedAutoInvoicing == "yes"}
                                        />
                                        Yes
                                        <br />
                                      </label>

                                      <label>
                                        <input
                                          type="radio"
                                          className="status"
                                          name="invoicing"
                                          value="no"
                                          onChange={handleAutoInvoiceSetup}
                                          checked={selectedAutoInvoicing == "no"}
                                        />
                                        No
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {defaultAutoInvoicesEditFlag && (
                            <>
                              <div className="formbold-form-btn-wrapper justify-content-end">
                                <div className="btn-end">
                                  <Link
                                    className="cancel"
                                    onClick={handleCancelAttendFlag}
                                  >
                                    Cancel
                                  </Link>

                                  <button
                                    className="formbold-btn"
                                    onClick={formSubmit}
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Student Portal */}
                {hasEditAccess &&
                  <div className="card">
                    <div
                      className="accordion accordion-flush"
                      id="accordionFlushExample"
                    >
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="flush-headingSeven">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseSeven"
                            aria-expanded="false"
                            aria-controls="flush-collapseSeven"
                          >
                            <strong>Student Portal</strong>
                          </button>
                        </h2>
                        <div
                          id="flush-collapseSeven"
                          className="accordion-collapse collapse"
                          aria-labelledby="flush-headingSeven"
                          data-bs-parent="#accordionFlushExample"
                        >
                          <div className="accordion-body">
                            <div className="access">
                              <h3>Student Access</h3>
                              <input
                                checked={checked}
                                onChange={() => handleToggle('student')}
                                className="switch-checkbox"
                                id={`switch`}
                                type="checkbox"
                                disabled={!isStudentEmailExists}
                                name="student-login-access"
                              />
                              <label
                                style={{ background: (!isStudentEmailExists) ? "#808080" : (checked == 1) ? "#06D6A0" : "#EF476F" }}
                                className="switch-label"
                                htmlFor={`switch`}
                              >
                                <span className={`switch-button`} />
                              </label>
                            </div>
                            <p>Set up Student Portal access for this student</p>
                            {!isStudentEmailExists && (
                              <div className="student-access">
                                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                <strong>
                                  If you'd like to enable student login access to have their own
                                  access to the Portal, you'll first need to provide an email address
                                  in their profile.
                                </strong>
                              </div>
                            )}
                            <hr></hr>
                            <div className="access">
                              <h3>Parent Access</h3>
                              <input
                                checked={checkedParentAccess}
                                onChange={() => handleToggle('parent')}
                                className="switch-checkbox"
                                id={`switchparent`}
                                type="checkbox"
                                name="parent-login-access"
                                disabled={!isParentEmailExists}
                              />
                              <label
                                style={{ background: (!isParentEmailExists) ? "#808080" : (checkedParentAccess == 1) ? "#06D6A0" : "#EF476F" }}
                                className="switch-label"
                                htmlFor={`switchparent`}
                              >
                                <span className={`switch-button`} />
                              </label>
                            </div>
                            <p>
                              Set up Parent Portal access for this student's
                              parents
                            </p>
                            {!isParentEmailExists && (
                              <div className="student-access">
                                <i
                                  class="fa fa-exclamation-triangle"
                                  aria-hidden="true"
                                ></i>

                                <strong>
                                  If you'd like to enable parent login access to have their own
                                  access to the Portal, you'll first need to provide an parent email address
                                  in parent details.
                                </strong>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default StudentEditDetails;
