import React, { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import Select from "react-select";
import DayTabInput from "../../../form/day-tab-input/DayTabInput"; 
import { bodyOpenClassName } from "react-modal/lib/components/Modal";
import { Button } from "@mui/material";
import ChecklistRtl from '@mui/icons-material/ChecklistRtl';

export const StudentEventModal = ({
      bodyOpenClassName,
      isOpen,
      afterOpenModal,
      closeModal,style,contentLabel,
      selectedEventId,
      eventDuration,eventTitle,eventDesc,eventStartTime,eventEndTime,
      attendees_info,takeAttendanceHandler,tutorName, tutorId, takeAttendance, eventData, 
      convertDateForPreview, event_text_date}) => (
        
      <ReactModal
        bodyOpenClassName={bodyOpenClassName}
        isOpen={isOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={style}
        contentLabel={contentLabel}
      >
        <div className="calendar-modal">
          <div className="calendar-date-time">
            <div className="close-h p-0">
              <p className="event-title">{eventTitle}</p>
              <button className="closeModal" onClick={closeModal}>
                X
              </button>
            </div>
            
            {tutorName != "" &&
              <div className="current-date p-0">
                <i class="fa fa-user" aria-hidden="true"></i>
                Lesson with  {tutorName}
              </div>
            }
            <div className="current-date p-0">
              <i class="fa fa-calendar" aria-hidden="true"></i>
              {convertDateForPreview(event_text_date)}
            </div>
            <div className="current-date p-0">
              <i class="fa fa-clock" aria-hidden="true"></i>
              {eventStartTime} - {eventEndTime} ({eventDuration} Mins)
            </div>
            <div className="list-attendees py-2">
              <label>{eventData.attendance_pending ? "Your Attendance" : "Attendance Not Taken"}</label>
              {attendees_info?.map((e) => {
                return (
                  <div className="d-flex justify-content-between">
                    <p>{e.name}</p>
                    <p>
                      {e.attendance_taken && e.attendance_status == 2 && (
                        <i
                          class="fa fa-check-circle"
                          style={{ color: "green" }}
                          aria-hidden="true"
                        ></i>
                      )}
                      {e.attendance_taken && e.attendance_status != 2 && (
                        <i
                          class="fa fa-times-circle"
                          style={{ color: "red" }}
                          aria-hidden="true"
                        ></i>
                      )}
                      {e.attendance_status == 1 && "(U)"}
                      {e.attendance_status == 2 && "(P)"}
                      {e.attendance_status == 3 && "(A)"}
                      {e.attendance_status == 4 && "(TC)"}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </ReactModal>
)