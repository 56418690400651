import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar, GridValueGetterParams } from "@mui/x-data-grid";
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import students from "../users/assets/images/students.svg";
import Loader from "../Loader.js";
import { Link, useParams } from "react-router-dom";
import { chevronRight } from "react-icons-kit/feather/chevronRight";
import { useNavigate } from "react-router-dom";
import DeleteModel from "../form/delete-model/DeleteModel.js";
import { ToastContainer, toast } from "react-toastify";
import { deleteChargeCategories } from "../../services/categoriesService.js";
import transaction from "../../assets/images/transactions.svg";
import { deleteTransactionById } from "../../services/invoiceService.js";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
const FetchFamilyTransactionDatatable = ({fromDate,toDate}) => {
  const param = useParams();
  const [val, setVal] = useState(false);
  const { convertDateForPreview, allTransactionsByFamily, fetchTransactionsByFamily, userId } =
    useUserDataContext();
  const [deleteId, setDeleteId] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    fetchTransactionsByFamily(param.id, fromDate, toDate);
  }, [userId, param, fromDate, toDate ]);
  
  const columns = [
    { field: "id", headerName: "ID", width: 90 },
    {
      field: "transaction_date",
      headerName: "Date",
      width: 150,
      renderCell: (params) =>
        `${convertDateForPreview(params.row.transaction_date)}`,
    },
    {
      field: "student_name",
      headerName: "Student",
      width: 150,
      renderCell: (params) =>
        `${params.row.student_name}`,
    },
    {
      field: "description",
      headerName: "Description",
      width: 150,
    },
    {
      field: "transaction_amount",
      headerName: "Transaction Amount",
      width: 150,
      renderCell: (params) => (
        <>
          {params.row.transaction_type == 1 && (
            <div
              style={{
                background: "green",
                padding: "2px 5px",
                color: "white",
              }}
            >
              {params.row.transaction_label} {params.row.formatted_transaction_amount}
            </div>
          )}
          {params.row.transaction_type == 2 && (
            <div
              style={{
                background: "green",
                padding: "2px 5px",
                color: "white",
              }}
            >
              {params.row.transaction_label} {params.row.formatted_transaction_amount}
            </div>
          )}
          {params.row.transaction_type == 3 && (
            <div
              style={{ background: "red", padding: "2px 5px", color: "white" }}
            >
              {params.row.transaction_label} -{params.row.formatted_transaction_amount}
            </div>
          )}
          {params.row.transaction_type == 4 && (
            <div
              style={{ background: "red", padding: "2px 5px", color: "white" }}
            >
              {params.row.transaction_label} -{params.row.formatted_transaction_amount}
            </div>
          )}
        </>
      ),
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <div className="grid-edit-btn">
          {params.row.editable && (
            <IconButton size="small" onClick={() =>{
                console.log("params-------------", params);
                navigate("/families-and-invoices/transaction-type/2/" + params.row.transaction_type + "/" + param.id + "/" + params.row.id )}}>
                  <EditIcon fontSize="small"/>
            </IconButton>
          )}
          {params.row.deletable && (
            <IconButton size="small" onClick={() => onDeleteModelHandler(params.row.id)}>
              <DeleteIcon fontSize="small"/>
            </IconButton>
          )}
        </div>
      ),
    },
  ];
  

  const onDeleteModelHandler = (id) => {
    setDeleteId(id);
    setDeleteModalIsOpen(true);
  };

 const onDeleteHandler = async (id) => {
    setIsDeleteLoading(true);
    const response = await deleteTransactionById(id);
    // const response = await deleteChargeCategories(id);
    if (response.success == true) {
      fetchTransactionsByFamily(param.id);
      toast.success(response.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      setDeleteModalIsOpen(false);
      setIsDeleteLoading(false);
      fetchTransactionsByFamily(param.id, fromDate, toDate);
    } else {
      setDeleteModalIsOpen(false);
      setIsDeleteLoading(false);
      toast.error(JSON.stringify(response.response.data.data), {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };  

  useEffect(() => {
    setVal(true);
    console.log("all tarnsaction by family---------------",allTransactionsByFamily);
  }, [allTransactionsByFamily]);
  if (val) {
    var rows = allTransactionsByFamily;
  }
  
  return (
    <div>
      <ToastContainer />
      <DeleteModel
        isLoading={isDeleteLoading}
        setIsLoading={setIsDeleteLoading}
        modalIsOpen={deleteModalIsOpen}
        id={deleteId}
        setIsOpen={setDeleteModalIsOpen}
        onOk={onDeleteHandler}
      />
      <>
        {rows && allTransactionsByFamily.length > 0 ? (
          <>
            <div className="py-3">
              <div className="chart chart-xs">
                <Box
                  sx={{
                    height: 400,
                    width: "100%",
                    "& .MuiDataGrid-columnHeaders": {
                      fontSize: "15px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      fontWeight: "700",
                    },
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                          referrer: false,
                          skill: false,
                          gender: false,
                          dob: false,
                          skype: false,
                          parentfirstname: false,
                          studentsince: false,
                          facetime: false,
                          price: false,
                          subjects: false,
                          school: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 10,
                        },
                      },
                    }}
                    pageSizeOptions={[20]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                </Box>
              </div>
            </div>
          </>
        ) : (
          <>
            <>
              <div className="py-3">
                <div className="chart chart-xs">
                  <img src={transaction}></img>
                </div>
              </div>
              <h4>
                <strong>
                  There aren't any transactions for this date range test
                </strong>
              </h4>
              <div className="addnewstudent addnew">
                <Link to={"/families-and-invoices/transaction-type/1/" + param.id} >
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  <a className="btn dropdown-toggle" href="#" role="button">
                    Add New
                  </a>
                </Link>

                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                ></div>
              </div>
            </>
          </>
        )}
      </>
    </div>
  );
};

export default FetchFamilyTransactionDatatable;
