// PayrollSettings.js
import React from 'react';
import payroll from '../../users/assets/images/payroll.svg';

/**
 * PayrollSettings component displays payroll information and settings.
 */
const PayrollSettings = () => (
  <div className="card">
    {/* Accordion for Payroll section */}
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item">
        {/* Accordion header */}
        <h2 className="accordion-header" id="flush-headingThree">
          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
            <strong>Payroll</strong>
          </button>
        </h2>
        {/* Accordion collapse content */}
        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
          <div className="accordion-body">
            <div className="row">
              <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                <div className="flex-fill w-100">
                  <div className="py-3">
                    {/* Placeholder for payroll image */}
                    <div className="chart chart-xs payroll-img">
                      <img src={payroll} alt="payroll" />
                    </div>
                  </div>
                  {/* Message for no payroll entries */}
                  <h6 className="text-center">
                    <strong>You haven't added any payroll entries yet</strong>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PayrollSettings;
