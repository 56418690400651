// ProfileCard.js
import React from "react";

/**
 * ProfileCard component displays user profile information.
 * @param {Object} props - Component props.
 * @param {string} props.profilePhoto - URL for profile photo.
 * @param {string} props.initial - Initials of the user.
 * @param {Object} props.userData - Object containing user data (first_name, last_name, email).
 * @param {Function} props.openModal - Function to open modal dialogs.
 */
const ProfileCard = ({ profilePhoto, initial, userData, openModal }) => (
  <div className="card">
    <div className="card-body">
      {/* Profile photo or initials */}
      <div className="initials">
        <div className="image-user">
          {profilePhoto ? (
            <img src={profilePhoto} alt="Profile" />
          ) : (
            <h2>{initial && initial.toLocaleUpperCase()}</h2>
          )}
        </div>
      </div>

      {/* Edit profile icon */}
      <div className="edit-user" onClick={() => openModal("profile")}>
        <i className="fa fa-pencil" aria-hidden="true"></i>
      </div>

      {/* User's full name */}
      <div className="title-user">
        {userData.first_name} {userData.last_name}
      </div>

      {/* User's email */}
      <div className="email-user">
        <i className="fa fa-envelope" aria-hidden="true"></i> {userData.email}
      </div>

      {/* Location information (Placeholder text) */}
      <div className="location-user">
        <i className="fa fa-map-marker" aria-hidden="true"></i> First Available
        Location
      </div>

      {/* Logout and change password links */}
      <div className="logout-user">
        <a href="#" className="logout">
          Log Out of All Devices
        </a>
        <a onClick={() => openModal("password")}>Change Password</a>
      </div>
    </div>
  </div>
);

export default ProfileCard;
