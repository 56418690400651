// AvailabilitySettings.js
import React from 'react';
import AvailabilityForm from './AvailabilityForm';

/**
 * AvailabilitySettings component displays availability settings and form for adding/editing availability.
 * @param {Object} props - Component props.
 * @param {Function} props.getAvailabilityData - Function to fetch availability data.
 * @param {boolean} props.availFlag - Flag indicating if availability form is open.
 * @param {Function} props.setAvailFlag - Function to toggle availability form visibility.
 * @param {Function} props.handleAvailChange - Function to handle changes in availability form fields.
 * @param {Function} props.handleAvailChangePopup - Function to handle changes in availability popup form fields.
 * @param {Function} props.formAvailSubmit - Function to submit availability form data.
 * @param {Function} props.editAvailability - Function to edit availability data.
 * @param {Function} props.deleteAvailability - Function to delete availability data.
 * @param {Array} props.availData - Array of availability data items.
 * @param {string} props.error - Error message, if any, related to availability form submission.
 */
const AvailabilitySettings = ({
  getAvailabilityData,
  availFlag,
  setAvailFlag,
  handleAvailChange,
  handleAvailChangePopup,
  formAvailSubmit,
  editAvailability,
  deleteAvailability,
  availData,
  error,
  convertDateForPreview
}) => (
  <div className="card">
    {/* Accordion for Availability section */}
    <div className="accordion accordion-flush" id="accordionFlushExample">
      <div className="accordion-item">
        {/* Accordion header */}
        <h2 className="accordion-header" id="flush-headingTwo">
          <button
            className="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#flush-collapseTwo"
            aria-expanded="false"
            aria-controls="flush-collapseTwo"
          >
            <strong>Availability</strong>
          </button>
        </h2>
        {/* Accordion collapse content */}
        <div
          id="flush-collapseTwo"
          className="accordion-collapse collapse"
          aria-labelledby="flush-headingTwo"
          data-bs-parent="#accordionFlushExample"
        >
          <div className="accordion-body">
            {/* Section for displaying availability details */}
            <div className="student-properties-edit sec-acc">
              <h3>Availability</h3>
              {/* Button to add new availability */}
              <button
                className="formbold-btn"
                style={{ fontSize: '14px', padding: '6px 16px' }}
                onClick={() => setAvailFlag(!availFlag)}
              >
                <i style={{ color: '#ffffff' }} className="fa fa-plus" aria-hidden="true"></i> Add
              </button>
            </div>

            {/* Description about setting availability */}
            <div className="formbold-input-flex">
              <span style={{ lineHeight: "1.6" }}>
                Enter your tutoring availability here to provide a
                visual cue of your availability on the calendar
                (visible in "Day" view or "Timeline" view).
                Setting your availability will not add or remove
                lessons from the calendar, or prevent lessons from
                being scheduled outside of these days/times.
              </span>
            </div>

            {/* Message if availability hasn't been added */}
            <p>You haven't added your tutoring availability yet</p>

            {/* Conditional rendering based on availFlag */}
            {!availFlag ? (
              // Display existing availability items
              <>
                {getAvailabilityData.map((item, index) => {
                  let day = JSON.parse(item.days);
                  return (
                    <div className="edit-availability-section" key={index}>
                      {/* Display availability details */}
                      <div className="availability-data">
                        <div className="d-flex">
                          {day.map((single_day, index) => (
                            <strong key={index}>{single_day}{index !== day.length - 1 && ', '}</strong>
                          ))}
                        </div>
                        <span>{convertDateForPreview(item.start_date)} to {convertDateForPreview(item.end_date)}</span>
                        <span>{item.note}</span>
                      </div>
                      {/* Display availability time */}
                      <div className="availability-time">
                        <span>{item.start_time} to {item.end_time}</span>
                      </div>
                      {/* Icons for editing and deleting availability */}
                      <div className="availability-edit-del">
                        <i className="fa fa-pencil" aria-hidden="true" onClick={() => editAvailability(item.id)}></i>
                        <i className="fa fa-trash" aria-hidden="true" onClick={() => deleteAvailability(item.id)}></i>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              // Display availability form for adding/editing availability
              <AvailabilityForm
                availData={availData}
                handleAvailChange={handleAvailChange}
                handleAvailChangePopup={handleAvailChangePopup}
                formAvailSubmit={formAvailSubmit}
                error={error}
                setAvailFlag={setAvailFlag}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AvailabilitySettings;
