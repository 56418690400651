import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CurrencyService from "../../../services/currencyService";
import IconDatePicker from "../../form/date-picker-input/IconDatePicker";

const PolicySettings = ({ handleChange, formData, setFormData, formSubmit, 
  isAllowToSetMakeUpLimit, setIsAllowToSetMakeUpLimit,
  isAllowToSetExpireUnUsedCredits, setIsAllowToSetExpireUnUsedCredits }) => {
  const { t } = useTranslation();
  const selectedCurrency = CurrencyService.getCurrency();
 
  const handleChangeMakeUpCreditLimit = (e) => {
    handleChange(e);
    setIsAllowToSetMakeUpLimit(!isAllowToSetMakeUpLimit);
  };

  const handleChangeExpireUnUsedCredits = (e) => {
    handleChange(e);
    setIsAllowToSetExpireUnUsedCredits(!isAllowToSetExpireUnUsedCredits);
  };

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingPolicy">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapsePolicy"
              aria-expanded="false"
              aria-controls="flush-collapsePolicy"
            >
              <strong>{t("Policy Settings")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapsePolicy"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingPolicy"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">

              {/* Signup policy */}
              <div className="mt-3 mb-4">
                <div className="student-properties-edit sec-acc justify-content-between p-1 mb-3">
                  <h3>{t("Sign-Up Policy")}</h3>
                </div>

                <div className="ps-2">
                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("Event sign-up in Student Portal")}
                    </label>
                    <br />
                    <div>
                      <label className="preference ps-3">
                        <input
                        type="checkbox"
                        name="allow_event_sign_up"
                        onChange={handleChange}
                        checked={formData.allow_event_sign_up === 1 ? true : false} 
                        />{" "}
                        {t("Allow event sign-up on this platform")}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("Allow prior Sign-Up up to (hours)")}
                    </label>
                    <input
                      type="number"
                      name="allow_sign_up_to_hours"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.allow_sign_up_to_hours}
                    />
                    <div>
                      <label className="preference p-1">
                        <input
                        type="checkbox"
                        name="log_in_app_notification_when_a_sign_up_occurs"
                        onChange={handleChange}
                        checked={
                            formData.log_in_app_notification_when_a_sign_up_occurs === 1 ? true : false}
                        />{" "}
                        {t("Log in-app notification when a sign-up occurs")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <hr />

              {/* Make-Up Credit Policy */}
              <div className="mt-4 mb-4">
                <div className="mb-2">
                  <div className="student-properties-edit sec-acc justify-content-between p-1 mb-0">
                    <h3>{t("Make-Up Credit Policy")}</h3>
                  </div>
                  <span className="p-1">These settings allow you to fine-tune your make-up policy.</span>
                </div>

                <div className="ps-2 mt-3">
                  <div className="form-group">
                    <div>
                      <label className="preference ps-3 mb-3">
                        <input
                        type="checkbox"
                        name="limit_make_up_credits"
                        onChange={handleChangeMakeUpCreditLimit}
                        checked={formData.limit_make_up_credits === 1 ? true : false}
                        />{" "}
                        {t("Limit number of make-up credits")}
                      </label>
                    </div>
                    {isAllowToSetMakeUpLimit && (
                      <div className="mb-4">
                        <div className="mt-3">
                          <span>Max Number of Make-Up Credits</span>
                          <div className="input-radio">
                              <input
                                type="number"
                                onChange={handleChange}
                                name="max_number_of_makeup_credit"
                                className="form-control"
                                value={formData.max_number_of_makeup_credit}
                              />
                          </div>
                        </div>
                        <div className="mt-3">
                          <span>Issued On or After</span>
                          <div className="input-radio">
                              <IconDatePicker
                                selectedDate={formData.makeup_credit_issued_from_date}
                                onChange={(date) => setFormData({ ...formData, ['makeup_credit_issued_from_date']: date })}
                              />
                          </div>
                        </div>
                      </div>
                    )}
                    <div>
                      <label className="preference ps-3">
                        <input
                        type="checkbox"
                        name="expire_unused_make_up_credits"
                        onChange={handleChangeExpireUnUsedCredits}
                        checked={formData.expire_unused_make_up_credits === 1 ? true : false}
                        />{" "}
                        {t("Expire unused make-up credits")}
                      </label>
                    </div>
                    {isAllowToSetExpireUnUsedCredits && (
                      <div className="mt-3 mb-2">
                        <span>Expire Unused Make-Up Credits After</span>
                        <div className="input-radio">
                          <div className="input-group mb-1">
                            
                            <input
                              type="number"
                              onChange={handleChange}
                              name="expire_unused_makeup_credit_after"
                              className="form-control"
                              value={formData.expire_unused_makeup_credit_after}
                            />
                            <div className="input-group-append">
                              <span className="input-group-text" style={{ height: '100%' }}>Days</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr />

              {/* Cancellation Policy */}
              <div className="mt-4 mb-4">
                <div className="student-properties-edit sec-acc justify-content-between p-1">
                  <h3>{t("Cancellation Policy")}</h3>
                </div>
                <div className="ps-2">
                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("Event Cancellation in Student Portal")}
                    </label>
                    <br />
                    <div>
                      <label className="preference ps-3">
                        <input
                        type="checkbox"
                        name="allow_event_cancellation"
                        onChange={handleChange}
                        checked={
                            formData.allow_event_cancellation === 1 ? true : false}
                        />{" "}
                        {t("Allow event cancellation on this platform")}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("Advance cancellation up to (hours)")}
                    </label>
                    <input
                      type="number"
                      name="advance_cancellation_hours"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.advance_cancellation_hours}
                    />
                    <div>
                      <label className="preference p-1">
                        <input
                        type="checkbox"
                        name="log_in_app_notification_when_cancellation_occurs"
                        onChange={handleChange}
                        checked={
                            formData.log_in_app_notification_when_cancellation_occurs === 1 ? true : false}
                        />{" "}
                        {t("Log in-app notification when a cancellation occurs")}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("If an event is cancelled before a deadline")}
                    </label>
                    <select
                      name="event_is_cancelled_before_deadline"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.event_is_cancelled_before_deadline}
                    >
                      <option value="mark_absent_bill_and_give_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                      <option value="mark_absent_notice_given">{t('Mark as "Absent, Notice Given"')}</option>
                      <option value="remove_student_from_attendee_list">{t("Remove student from attendee list")}</option>
                    </select>
                    <div>
                      <label className="preference p-1">
                        <input
                        type="checkbox"
                        name="convert_event_to_make_up_slot_before_deadline"
                        onChange={handleChange}
                        checked={
                            formData.convert_event_to_make_up_slot_before_deadline === 1 ? true : false}
                        />{" "}
                        {t("Convert event to make-up slot")}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("If an event is cancelled after a deadline")}
                    </label>
                    <select
                      name="event_is_cancelled_after_deadline"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.event_is_cancelled_after_deadline}
                    >
                      <option value="mark_absent_bill_and_give_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                      <option value="mark_absent_billable_and_no_make_up_credit">{t(`Mark as 'Absent, Bill & Give Make-Up Credit ${selectedCurrency}'`)}</option>
                    </select>
                    <div>
                      <label className="preference p-1">
                        <input
                        type="checkbox"
                        name="convert_event_to_make_up_slot_after_deadline"
                        onChange={handleChange}
                        checked={
                            formData.convert_event_to_make_up_slot_after_deadline === 1 ? true : false}
                        />{" "}
                        {t("Convert event to make-up slot")}
                      </label>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="formbold-form-label">
                      {t("Policy Text")}
                    </label>
                    <textarea
                      name="policy_text"
                      className="form-control"
                      onChange={handleChange}
                      value={formData.policy_text}
                    ></textarea>
                  </div>
                </div>
              </div>

              {/* Save Changes */}
              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PolicySettings;
