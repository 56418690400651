const CurrencyService = {
    getCurrency() {
        // Retrieve the currency symbol from session storage
        let currency = sessionStorage.getItem('currency_symbol') || '$'; // default to USD if not set
        
        // Check if the currency has quotation marks and remove them
        if (currency.startsWith('"') && currency.endsWith('"')) {
            currency = currency.slice(1, -1);
        }
        
        return currency;
    }
};

export default CurrencyService;


