import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import { useUserDataContext } from "../../../contextApi/userDataContext";
import './css/TagInput.css';
import Select from "react-select";
import CurrencyService from "../../../services/currencyService";
import { API_URL } from "../../../utils/config.js";
import axios from "axios";
import IconDatePicker from "../../form/date-picker-input/IconDatePicker";

const FamilyAccountSettings = ({ handleChange, formData, setFormData, formSubmit, tags, setTags,
                                  chargeCategoryId, setChargeCategoryId,
                                  showDayOfMonth, setShowDayOfMonth, showSpecificDate, setShowSpecificDate,
                                  showFixedAmountFields, setShowFixedAmountFields, showPercentageAmountFields, 
                                  setShowPercentageAmountFields,invoiceLogo,setInvoiceLogo,emailTimeFrame, setEmailTimeFrame
 }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { fetchChargeCategory, allChargeCategory, fetchEmailTimeFrame, allEmailTimeFrame } = useUserDataContext();
  const [selectedChargeCategory, setSelectedChargeCategory] = useState(null);
  const [selectedEmailTimeFrame, setSelectedEmailTimeFrame] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const selectedCurrency = CurrencyService.getCurrency();

  useEffect(()=>{
    fetchChargeCategory();
    fetchEmailTimeFrame();
  },[])

  useEffect(() => {
    const selected = allChargeCategory.find(e => e.id === chargeCategoryId);
    setSelectedChargeCategory(selected ? { value: selected.id, label: selected.chargecat_name } : null);
  }, [chargeCategoryId, allChargeCategory]);

  useEffect(() => {
    const selected = allEmailTimeFrame.find(e => e.id === emailTimeFrame);
    setSelectedEmailTimeFrame(selected ? { value: selected.id, label: selected.timeframe } : null);
  }, [allEmailTimeFrame, emailTimeFrame]);

  const handleBalanceDateChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    setShowDayOfMonth(value === "dayOfMonth");
    setShowSpecificDate(value === "specificDate");
  };

  const handleLatePaymentFeeChange = (e) => {
    handleChange(e);
    const value = e.target.value;
    setShowFixedAmountFields(value === "fixed");
    setShowPercentageAmountFields(value === "percentage");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' || e.key === ';') {
      e.preventDefault();
      const newTag = inputValue.trim().replace(/;$/, '');
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
      }
      setInputValue('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setTags(tags.filter((tag) => tag !== tagToRemove));
  };

  const uploadInvoiveLogo = async (e) => {
    const config = {
      method: "POST",
      url: `${API_URL}business-settings/invoice-logo`,
      headers: {
        "content-type": "multipart/form-data",
      },
      data: {"invoice_logo" : e.target.files[0]},
    };
    await axios(config)
      .then((response) => {
        setInvoiceLogo(response.data.data.invoice_logo);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <div className="card">
      <div className="accordion accordion-flush" id="accordionFlushExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="flush-headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseThree"
              aria-expanded="false"
              aria-controls="flush-collapseThree"
            >
              <strong>{t("Accounts & Invoices")}</strong>
            </button>
          </h2>
          <div
            id="flush-collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="flush-headingThree"
            data-bs-parent="#accordionFlushExample"
          >
            <div className="accordion-body">
              {/* Family Account Section */}
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Family Account")}</h3>
              </div>
              <div className="form-group">
                <label htmlFor="payment_methods" className="formbold-form-label">
                  {t("Payment Methods")} <span>Optional</span>
                </label>
                <br />
                <input
                  type="text"
                  id="payment_methods"
                  className="form-control"
                  name="payment_methods"
                  value={inputValue}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                  placeholder={t("Enter payment methods separated by semicolon or press the Enter key")}
                />
                <div className="tags-container">
                  { tags.length > 0 &&
                    tags.map((tag, index) => (
                      <div key={index} className="tag">
                        {tag}
                        <button type="button" onClick={() => handleRemoveTag(tag)}>
                          &times;
                        </button>
                      </div>
                  ))}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="balance_date" className="formbold-form-label">
                  {t("Default Balance Date")}
                </label>
                <div className="p-2">
                  <label>
                    <input
                      type="radio"
                      id="today"
                      name="balance_date"
                      value="today"
                      onChange={handleBalanceDateChange}
                      checked={formData?.balance_date === "today"}
                    />{" "}
                    <lable htmlFor="today">{t("Today")}</lable>
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      id="endOfMonth"
                      name="balance_date"
                      value="endOfMonth"
                      onChange={handleBalanceDateChange}
                      checked={formData?.balance_date === "endOfMonth"}
                    />{" "}
                    <lable htmlFor="endOfMonth">{t("End of month")}</lable>
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      id="dayOfMonth"
                      name="balance_date"
                      value="dayOfMonth"
                      onChange={handleBalanceDateChange}
                      checked={formData?.balance_date === "dayOfMonth"}
                    />{" "}
                    <lable htmlFor="dayOfMonth">{t("Day of month")}</lable>
                  </label>
                  <br />
                  <label>
                    <input
                      type="radio"
                      id="specificDate"
                      name="balance_date"
                      value="specificDate"
                      onChange={handleBalanceDateChange}
                      checked={formData?.balance_date === "specificDate"}
                    />{" "}
                    <lable htmlFor="specificDate">{t("Specific date")}</lable>
                  </label>
                  <br />
                </div>
                {showDayOfMonth && (
                  <div id="selectSpecificDate" className="mt-3">
                    <span>Select a day of the month between 1 and 31</span>
                    <input
                      type="text"
                      onChange={handleChange}
                      id="day_of_month"
                      name="day_of_month"
                      className="form-control"
                      value={formData.day_of_month}
                      placeholder={t("Select a day of the month between 1 and 31")}
                    />
                  </div>
                )}
                {showSpecificDate && (
                  <div id="selectSpecificDate" className="mt-3">
                    <span>Select a specific balance date</span>
                    <IconDatePicker
                      selectedDate={formData.specific_date}
                      onChange={(date) => setFormData({ ...formData, ['specific_date']: date })}
                    />
                  </div>
                )}
              </div>
              <div className="form-group mb-2">
                <label className="formbold-form-label">
                  {t("Prorate fees based on student’s joining date")}
                </label>
                <div className="p-2">
                  <input
                    type="checkbox"
                    id="pro_data_calculation"
                    name="pro_data_calculation"
                    onChange={handleChange}
                    checked={formData?.pro_data_calculation === 1}

                  />{" "}
                  <label htmlFor="pro_data_calculation">{t("If enabled, fees will be calculated from the student's joining date")}</label>
                  <br />
                </div>
              </div>
              <hr></hr>

              {/* Invoice Settings Section */}
              <div className="student-properties-edit sec-acc justify-content-between p-1">
                <h3>{t("Invoice Settings")}</h3>
              </div>
              <div className="form-group mb-3">
                <label htmlFor="late_payment_fee" className="formbold-form-label">
                  {t("Automatic Late Payment Fee")}
                </label>
                <div className="p-2">
                  <input
                    type="radio"
                    id="noFee"
                    name="late_payment_fee"
                    value="none"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "none"}
                  />{" "}
                  <label className="" htmlFor="noFee">{t("None")}</label>
                  <br />
                  <input
                    type="radio"
                    id="fixedAmount"
                    name="late_payment_fee"
                    value="fixed"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "fixed"}
                  />{" "}
                  <label className="" htmlFor="fixedAmount">{t(`Fixed amount (${selectedCurrency})`)}</label>
                  <br />
                  <input
                    type="radio"
                    id="percentage"
                    name="late_payment_fee"
                    value="percentage"
                    onChange={handleLatePaymentFeeChange}
                    checked={formData?.late_payment_fee === "percentage"}
                  />{" "}
                  <label className="" htmlFor="percentage">{t("Percentage (%)")}</label>
                  <br />
                </div>
                {showFixedAmountFields && (
                  <div className="mt-1">
                    <span>Enter a late fee amount</span>
                    <div className="input-radio">
                      <div className="input-group mb-1">
                        <div className="input-group-prepend">
                          <span className="input-group-text" style={{ height: '100%' }}>{selectedCurrency}</span>
                        </div>
                        <input
                           type="number"
                           onChange={handleChange}
                           name="late_fee_amount"
                           className="form-control"
                           value={formData.late_fee_amount}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {showPercentageAmountFields && (
                  <div className="mt-1">
                    <span>Enter a late fee percentage (%)</span>
                    <input
                      type="text"
                      onChange={handleChange}
                      name="late_fee_percentage"
                      className="form-control"
                      value={formData.late_fee_percentage}
                    />
                  </div>
                )}
                {(showFixedAmountFields || showPercentageAmountFields) && (
                  <div>
                    <div className="mt-3">
                      <span>How many days after the due date should the late fee be applied?</span>
                      <input
                        type="number"
                        onChange={handleChange}
                        name="late_fee_apply_after_days"
                        className="form-control"
                        value={formData.late_fee_apply_after_days}
                      />
                    </div>
                    <div className="mt-2">
                      <div className="d-flex align-items-end justify-content-between">
                      <span>Select a category to use for automatic late fees</span>
                      <div className="btn-end">
                        <Button size="small" startIcon={<AddIcon />} onClick={() => navigate('/calendar/categories')}>
                          Add Category
                        </Button>
                      </div>
                      </div>
                      <Select 
                        onChange={(e)=>{setSelectedChargeCategory(e);setChargeCategoryId(e.value)}} 
                        isMulti={false} 
                        name="category_for_late_fees"
                        options={[...allChargeCategory.map((e)=>{return {value:e.id,label:e.chargecat_name}})]} 
                        value={selectedChargeCategory}
                      />
                      </div>
                  </div>
                )}
              </div>
              <div className="form-group mb-2">
                <label className="formbold-form-label">
                  {t("Notifications & Reminders")}
                </label>
                <div className="p-2">
                  <input
                    type="checkbox"
                    id="send_invoice_notification"
                    name="send_invoice_notification"
                    onChange={handleChange}
                    checked={formData?.send_invoice_notification === 1}

                  />{" "}
                  <label htmlFor="send_invoice_notification">{t("Send SMS invoice notifications and invoice reminders")}</label>
                  <br />
                  <input
                    type="checkbox"
                    id="send_over_due_invoice_reminder"
                    name="send_over_due_invoice_reminder"
                    onChange={handleChange}
                    checked={formData?.send_over_due_invoice_reminder === 1}

                  />{" "}
                  <label htmlFor="send_over_due_invoice_reminder">{t("Send an overdue invoice reminder")}</label>
                  <br />
                </div>
              </div>
              <div className="form-group mb-2">
                <label htmlFor="email_time_frame" className="formbold-form-label">
                  {t("Email Timeframe")}
                </label>
                <Select 
                  onChange={(e)=>{setSelectedEmailTimeFrame(e);setEmailTimeFrame(e.value);}} 
                  isMulti={false} 
                  id="email_timeframe"
                  name="email_timeframe"
                  options={[...allEmailTimeFrame.map((e)=>{return {value:e.id,label:e.timeframe}})]} 
                  value={selectedEmailTimeFrame}
                />
              </div>
              <hr />

              {/* Invoice Formatting Section */}
              <div className="student-properties-edit sec-acc justify-content-between mt-3 p-1">
                <h3>{t("Invoice Formatting")}</h3>
              </div>
              <div className="formbold-input-flex justify-content-center">
                <div className="student-profile-view">
                  <label htmlFor="photo" className="formbold-form-label">
                    Invoice Logo
                  </label>
                  <div className="initials py-3 justify-content-start">
                    <div className="image-user">
                      {invoiceLogo ? (
                        <img src={invoiceLogo} alt="Logo" />
                      ) : (
                        <h2>Logo</h2>
                      )}
                    </div>
                  </div>
                  <input
                    type="file"
                    name="invoice_logo"
                    className="form-control b-none"
                    onChange={uploadInvoiveLogo}
                    style={{ height: "auto" }}
                  />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="invoice_name" className="formbold-form-label">
                  {t("Invoice Name")}
                </label>
                <input
                  type="text"
                  id="invoice_name"
                  onChange={handleChange}
                  name="invoice_name"
                  className="form-control"
                  placeholder={t("Enter invoice name")}
                  value={formData.invoice_name}
                />
              </div>
              <div className="formbold-input-flex diff">
                <div>
                  <label htmlFor="preferences" className="formbold-form-label" >
                    {t("Invoice Number")}
                  </label>
                  <br />
                  <div className="ps-3 preference align-item-center">
                    <input
                      type="checkbox"
                      id="generate_invoice_number"
                      name="generate_invoice_number"
                      onChange={handleChange}
                      checked={formData?.generate_invoice_number === 1}
                    />
                    <label className="mb-0" htmlFor="generate_invoice_number">{t("Generate invoice number")}</label>
                  </div>
                </div>
              </div>
              <hr />

              <div className="form-group">
                <label className="formbold-form-label">
                  {t("Negative Invoices")}
                </label>
                <div className="p-2">
                  <input
                    type="radio"
                    id="showZeroDue"
                    name="negative_invoices"
                    onChange={handleChange}
                    value="zero_due"
                    checked={formData?.negative_invoices === "zero_due"}
                  />{" "}
                  <label className="" htmlFor="showZeroDue">{t(`Show as ${selectedCurrency} 0.00 due`)} </label>
                  <br />
                  <input
                    type="radio"
                    id="balanceRemaining"
                    name="negative_invoices"
                    onChange={handleChange}
                    value="balance_remaining"
                    cxhecked={formData?.negative_invoices === "balance_remaining"}
                  />{" "}
                  <label className="" htmlFor="balanceRemaining">{t("Show as 'Balance Remaining'")}</label>
                  <br />
                </div>
              </div>
              <div className="form-group mb-2">
                <label className="formbold-form-label">
                  {t("Options")}
                </label>
                <div className="p-2">
                  <input
                    type="checkbox"
                    id="show_location_on_invoice"
                    name="show_location_on_invoice"
                    onChange={handleChange}
                    checked={formData?.show_location_on_invoice === 1}

                  />{" "}
                  <label htmlFor="show_location_on_invoice">{t("Show location on invoice")}</label>
                  <br />
                  <input
                    type="checkbox"
                    id="group_charges_of_multi_students"
                    name="group_charges_of_multi_students"
                    onChange={handleChange}
                    checked={formData?.group_charges_of_multi_students === 1}

                  />{" "}
                  <label htmlFor="group_charges_of_multi_students">{t("Group charges together by student for families with multiple students")}</label>
                  <br />
                  <input
                    type="checkbox"
                    id="include_sales_tax_in_amount"
                    name="include_sales_tax_in_amount"
                    onChange={handleChange}
                    checked={formData?.include_sales_tax_in_amount === 1}

                  />{" "}
                  <label htmlFor="include_sales_tax_in_amount">{t("Include sales tax in line item amounts")}</label>
                  <br />
                </div>
              </div>
              <hr />

              <div className="form-group">
                <label htmlFor="invoice_footer_text" className="formbold-form-label">
                  {t("Invoice Footer")}
                </label>
                <textarea
                  id="invoice_footer_text"
                  onChange={handleChange}
                  name="invoice_footer_text"
                  className="form-control"
                  placeholder={t("Enter invoice footer text")}
                  value={formData.invoice_footer_text}
                ></textarea>
              </div>
              <div className="form-group">
                <label htmlFor="invoice_accent_color" className="formbold-form-label">
                  {t("Invoice Accent Color")}
                </label>
                <div className="color-picker-container">
                  <input
                    type="color"
                    id="invoice_accent_color"
                    onChange={handleChange}
                    name="invoice_accent_color"
                    value={formData.invoice_accent_color} 
                    className="color-input"
                  />
                  <span className="color-code-input">{formData.invoice_accent_color}</span>
                </div>
              </div>


              <div className="formbold-form-btn-wrapper justify-content-end">
                <div className="btn-end">
                  <button className="formbold-btn" onClick={formSubmit}>
                    {t("Save Changes")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FamilyAccountSettings;
