import React, { useState, useRef, useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './style.css'
import { useUserDataContext } from "../../contextApi/userDataContext";
import Select from "react-select";
import { API_URL } from "../../utils/config";
import axios from "axios";

const LessonDetails = ({
  email_student,
  set_email_student,
  email_tutor,
  set_email_tutor,
  student_notes,
  setStudentNotes,
  parent_notes,
  setParentNotes,
  private_notes,
  setPrivateNotes,
  selectedCourses,
  setSelectedCourses,
  selectedModules,
  setSelectedModules,
  selectedLessons,
  setSelectedLessons,
  course_id,
  setCourseId,
  module_id,
  setModuleId,
  lesson_id,
  setLessonId
}) => {
  const {isDarkMode, fetchCourseData, courseData,fetchModuleData, moduleData, fetchLessonData, lessonData} = useUserDataContext();
  const editorRef = useRef(null);
  const [selectedNoteType, setSelectedNoteType] = useState('private_notes');
  const [activeTab, setActiveTab] = useState('student_notes');
  const [coursesList, setCoursesList] = useState("");
  const [modulesList, setModuleList] = useState("");
  const [lessonList, setLessonList] = useState("");
  
  
  useEffect(()=>{
    fetchCourseData();
  },[])

  useEffect(() => {
    if (courseData) {
      const transformData = (data) => {
        const courses = data.map((e) => ({
          value: e.id,
          label: e.course_name,
        }));
        setCoursesList(courses);
      };
      transformData(courseData);
    }
  }, [courseData]);

  useEffect(() => {
    if (course_id && coursesList) {
      const selectedCourse = coursesList.find(course => course.value === course_id);
      setSelectedCourses(selectedCourse || ""); // Set pre-selected course
    }
  }, [course_id, coursesList]);

  useEffect(() => {
    if (module_id && modulesList) {
      const selectedModule = modulesList.find(module => module.value === module_id);
      setSelectedModules(selectedModule || ""); // Set pre-selected module
    }
  }, [module_id, modulesList]);

  useEffect(() => {
    if (lesson_id && lessonList) {
      const selectedLesson = lessonList.find(lesson => lesson.value === lesson_id);
      setSelectedLessons(selectedLesson || ""); // Set pre-selected lesson
    }
  }, [lesson_id, lessonList]);

  useEffect(() => {
    if (course_id) {
      fetchModuleDataBySingleCourseId(course_id);
    }
  }, [course_id]);

  useEffect(() => {
    if (module_id) {
      fetchLessonDataBySingleModuleId(module_id);
    }
  }, [module_id]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      backgroundColor: isDarkMode ? "#363636" : "white",
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDarkMode ? "#363636" : "white",
        color: isDarkMode ? "#FFF" : "black",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
  };

  const customStyles = {
    ...colourStyles, // Inherit your existing styles
    control: (provided, state) => ({
      ...provided,
      cursor: state.isDisabled ? "not-allowed" : "default",
      backgroundColor: state.isDisabled ? "#f0f0f0" : "white", // Optional: change background if disabled
    }),
  };

  const fetchModuleDataBySingleCourseId = async (courseId) => {
    const config = {
      method: "GET",
      url: `${API_URL}course/${courseId}`
    };
    try {
      const response = await axios(config);
      const modules = response.data.data.modules.map((module) => ({
        value: module.id,
        label: module.module_name,
      }));
      setModuleList(modules); // Set the modules for the selected course
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  const fetchLessonDataBySingleModuleId = async (moduleId) => {
    const config = {
      method: "GET",
      url: `${API_URL}module/${moduleId}`
    };
    try {
      const response = await axios(config);
      const lessons = response.data.data.lessons.map((lesson) => ({
        value: lesson.id,
        label: lesson.lesson_name,
      }));
      setLessonList(lessons); // Set the modules for the selected course
    } catch (error) {
      console.error("Error fetching modules:", error);
    }
  };

  const handleCourseChangeById = (selectedCourse) => {
    setSelectedCourses(selectedCourse); // Set selected course
    fetchModuleDataBySingleCourseId(selectedCourse.value); // Fetch modules for the selected course
    setSelectedModules(""); // Clear module selection when course changes
  };
  
  const handleModuleChangeByCourseId = (selectedModule) => {
    setSelectedModules(selectedModule);
    fetchLessonDataBySingleModuleId(selectedModule.value); // Fetch lessons for the selected module
    setSelectedLessons(""); // Clear lesson selection when module changes
  };

  const handleLessonChangeByModuleId = (selectedLesson) => {
    setSelectedLessons(selectedLesson);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="lesson-details">
      <p>Add notes, assignments, and link online resources</p>

      <div className="formbold-input-flex">
        <div>
          <label htmlFor="course_name" className="formbold-form-label">
            Course
          </label>
          <div>
            <Select
              value={selectedCourses}
              onChange={handleCourseChangeById}
              options={coursesList}
              styles={colourStyles}
            />
          </div>
        </div>

        <div>
          <label htmlFor="module_name" className="formbold-form-label">
            Module
          </label>
          <div>
            <Select
              value={selectedModules}
              onChange={handleModuleChangeByCourseId}
              options={modulesList}
              styles={customStyles}
              isDisabled={!selectedCourses} // Disable if no course selected
            />
          </div>
        </div>

        <div>
          <label htmlFor="module_name" className="formbold-form-label">
            Lesson
          </label>
          <div>
            <Select
              value={selectedLessons}
              onChange={handleLessonChangeByModuleId}
              options={lessonList}
              styles={customStyles}
              isDisabled={!selectedModules} // Disable if no module is selected
            />
          </div>
        </div>
      </div>


      <ul className="nav nav-tabs" id="notesTabs" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'student_notes' ? 'active' : ''}`}
            onClick={() => handleTabChange('student_notes')}
          >
            Student Notes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'parent_notes' ? 'active' : ''}`}
            onClick={() => handleTabChange('parent_notes')}
          >
            Parent Notes
          </button>
        </li>
        <li className="nav-item" role="presentation">
          <button
            className={`nav-link ${activeTab === 'private_notes' ? 'active' : ''}`}
            onClick={() => handleTabChange('private_notes')}
          >
            Private Notes
          </button>
        </li>
      </ul>

      {activeTab == "student_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={student_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setStudentNotes(data);
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      {activeTab == "parent_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={parent_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setParentNotes(data)
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      {activeTab == "private_notes" && (
        <CKEditor
          editor={ClassicEditor}
          data={private_notes}
          onChange={(event, editor) => {
            const data = editor.getData();
            setPrivateNotes(data)
          }}
          onReady={(editor) => {
            if (editorRef) editorRef.current = editor;
          }}
          config={{
            toolbar: [
              'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote',
              'imageUpload', 'indent', 'outdent', 'undo', 'redo'
            ],
            image: {
              toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
            },
          }}
        />
      )}

      <div className="email-outer-box">
        <label className="lessondetails-navwrapper">
          <input
            type="checkbox"
            checked={email_student}
            onChange={(e) => set_email_student(e.target.checked)}
            name="email_student"
          />
          <span className="lessons-email-wrapper">Email Students</span>
        </label>
        <label className="lessondetails-navwrapper">
          <input
            type="checkbox"
            checked={email_tutor}
            onChange={(e) => set_email_tutor(e.target.checked)}
            name="email_tutor"
          />
          <span className="lessons-email-wrapper" >Email Tutor</span>
        </label>
      </div>
    </div>
  );
};

export default LessonDetails;

