import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MiniSidebar from "../../sidebar/MiniSidebar.js";
import Sidebar from "../../sidebar/Sidebar.js";
import TopBar from "../../sidebar/TopBar.js";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
import instructors from "../assets/images/Instructors.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../assets/css/customDatepicker.css";
import Modal from "react-modal";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Invoice from "./Invoice.js";
import Family from "./Family.js";
import Transaction from "./Transaction.js";
import Select from "react-select";
import { user } from "react-icons-kit/feather/user";
import { Icon } from "react-icons-kit";
import { settings } from "react-icons-kit/feather/settings";
import { mail } from "react-icons-kit/feather/mail";
import { ic_receipt_outline } from "react-icons-kit/md/ic_receipt_outline";
import TransactionByFamily from "./TransactionByFamily.js";
import {
  getFamilyAccountsDetails,
  disableAutoInvoicesTransaction,
  getIncomingInvoice,
} from "../../../services/invoiceService.js";
import { Modal as BootstrapModal, Button } from "react-bootstrap";
import { Icon as ReactIcon } from "react-icons";
import './style.css';
import CurrencyService from "../../../services/currencyService.js";

const InvoiceDetails = () => {
  const {
    userData,
    sidebarToggle,
    fetchStudentData,
    studentData,
  } = useUserDataContext();
  const token = useUserDataContext();

  const param = useParams();
  // console.log('user details', userData.role_id);

  const navigate = useNavigate();
  const [selectedFamily, setSelectedFamily] = useState();
  const [incoming_invoice, set_incoming_invoice] = useState({});
  const [students, setStudents] = useState([]);
  const [family, set_family] = useState({});
  const [isAutoInvoicing, setIsAutoInvoicing] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const selectedCurrency = CurrencyService.getCurrency();



  const getFamilyAccountsDetailsHandler = async () => {
    const response = await getFamilyAccountsDetails(param.id);
    console.log("response from family accounts of family details--------", response);

    set_family(response?.data);
    setStudents(response?.data?.students || []);
    setIsAutoInvoicing(response?.data?.auto_invoicing || 0);
  };

  const getIncomingInvoiceHandler = async () => {
    const response = await getIncomingInvoice(param.id);
    if (response?.success === true) {
      set_incoming_invoice(response.data);
    }
  };

  const getStudentData = async () => {
    const response = await fetchStudentData(userData.id);

    if (response?.success === true) {
      set_incoming_invoice(response.data);
    }
  };

  useEffect(() => {
    getFamilyAccountsDetailsHandler();
    let datareturn = getStudentData();
  }, [userData.id]);

  // console.log('student details', studentData[0]);
  return (
    <div className="wrapper">
      {sidebarToggle ? (
        <>
          <MiniSidebar />
        </>
      ) : (
        <>
          <Sidebar />
        </>
      )}

      <div className="main">
        <TopBar />
        <main className="content student">
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-xl-4 col-xxl-4">
                <Link to={"/families-and-invoices"} className="link">
                  {`<`} Back To family & Invoice
                </Link>

                <div className="card enable-auto-invoice-wrapper">
                  <div className="card-body">

                    <div className="row">
                      <div className="col-md-12">
                        <h6
                          style={{
                            textAlign: "center",
                            marginTop: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          <strong>Family Details</strong>
                        </h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>Students</label>
                        <ul>
                          <li>
                            <Link to={"/students/details/" + studentData[0]?.id}><Icon icon={user} /> {studentData[0]?.first_name} {studentData[0]?.last_name}{" "}</Link>
                            <span style={{ background: `${studentData[0]?.status_color}`, padding: "2px 5px", color: "white" }} >
                              {studentData[0]?.status_label}
                            </span>
                          </li>

                        </ul>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <label>Contact</label>
                        <ul>
                          <li>
                            <Link to={"/students/details/" + studentData[0]?.id}>
                              {console.log("selected family--------------------", selectedFamily)}
                              <Icon icon={user} /> {userData.first_name} {userData.last_name}{" "}
                            </Link>
                            <span
                              style={{
                                background: "lightblue",
                                padding: "2px 5px",
                                color: "white",
                              }}
                            >
                              Invoice Recipient
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    {Boolean(isAutoInvoicing) && (
                      <>
                        <div className="row">
                          <div className="col-md-12">
                            <label>
                              <Icon icon={ic_receipt_outline} /> Next Invoice
                            </label>
                            <div style={{ lineHeight: "1.2", fontSize: 10 }}>
                              <p>
                                Invoice Date:{" "}
                                {incoming_invoice?.invoice_create_date}
                              </p>
                              <p>
                                Date Range:{" "}
                                {incoming_invoice?.invoice_start_date} to{" "}
                                {incoming_invoice.invoice_end_date}
                              </p>
                              <p>
                                Total Due:{" "}
                                <span
                                  style={{ background: "red", padding: "2px 5px", color: "white", }}>
                                  {selectedCurrency}{incoming_invoice?.amount}.00 balance owing
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>
                              <Icon icon={settings} /> Auto-Invoice Settings
                            </label>
                            <div style={{ lineHeight: "1.2", fontSize: 10 }}>
                              <p>
                                {family.is_prepaid_invoice === 0
                                  ? "Prepaid Lessons"
                                  : "Postpaid Lessons"}
                              </p>
                              <p>Repeats: The 1st of the month every 1 month</p>
                              <p>
                                Balance Forward:
                                <span
                                  style={{
                                    minWidth: "50px",
                                    padding: "2px 5px",
                                    background:
                                      family.balance_forward === 1
                                        ? "lightgreen"
                                        : "red",
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {family.balance_forward === 1
                                    ? "Enabled"
                                    : "Disabled"}
                                </span>
                              </p>
                              <p>
                                Auto-Email:
                                <span
                                  style={{
                                    minWidth: "50px",
                                    padding: "2px 5px",
                                    background:
                                      family.auto_email === 1
                                        ? "lightgreen"
                                        : "red",
                                    textAlign: "center",
                                    color: "white",
                                  }}
                                >
                                  {family.auto_email === 1
                                    ? "Enabled"
                                    : "Disabled"}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    <hr></hr>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-xxl-8">
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="transaction-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#transaction"
                      type="button"
                      role="tab"
                      aria-controls="transaction"
                      aria-selected="true"
                    >
                      Transaction
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="invoice-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#invoice"
                      type="button"
                      role="tab"
                      aria-controls="invoice"
                      aria-selected="true"
                    >
                      Invoice
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="transaction"
                    role="tabpanel"
                    aria-labelledby="transaction-tab"
                  >
                    <TransactionByFamily />
                  </div>
                  <div
                    className="tab-pane fade"
                    id="invoice"
                    role="tabpanel"
                    aria-labelledby="invoice-tab"
                  >
                    <Invoice />
                  </div>

                  <div
                    className="tab-pane fade"
                    id="profile"
                    role="tabpanel"
                    aria-labelledby="profile-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
                        <div className="card flex-fill w-100">
                          <div className="card-header">
                            <div className="dropdown addnew">
                              <i className="fa fa-plus" aria-hidden="true"></i>
                              <a
                                className="btn dropdown-toggle"
                                href="#"
                                role="button"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                Add Group
                              </a>

                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuLink"
                              >
                                <a className="dropdown-item" href="#">
                                  Action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Another action
                                </a>
                                <a className="dropdown-item" href="#">
                                  Something else here
                                </a>
                              </div>
                            </div>
                          </div>

                          <div className="card-body d-flex">
                            <div className="align-self-center w-100">
                              <div className="py-3">
                                <div className="chart chart-xs">
                                  <img src={instructors}></img>
                                </div>
                              </div>
                              <h4>
                                <strong>You don't have any Groups yet</strong>
                              </h4>
                              <p style={{ textAlign: "center" }}>
                                Keep your tutors organized by assigning them
                                into groups
                              </p>
                              <div className="addnewstudent">
                                <i
                                  className="fa fa-plus"
                                  aria-hidden="true"
                                ></i>
                                <a
                                  className="btn dropdown-toggle"
                                  href="#"
                                  role="button"
                                >
                                  Add Group
                                </a>

                                <div
                                  className="dropdown-menu"
                                  aria-labelledby="dropdownMenuLink"
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default InvoiceDetails;
