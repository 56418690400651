import React, { useState } from 'react';
import { MiniSidebar, Sidebar } from './sidebar';
import { useUserDataContext } from '../contextApi/userDataContext';
import { ToastContainer, toast } from "react-toastify";

const WidgetTabs = () => {
    const { sidebarToggle } = useUserDataContext()
    const [activeTab, setActiveTab] = useState('signup'); // Default active tab


    // Styles (Optional: can be moved to a CSS file or CSS-in-JS solution)
    const styles = {
        card: {
            border: '1px solid #ddd',
            borderRadius: '5px',
            padding: '20px',
            width: '400px',
            margin: '20px auto',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        },
        tabs: {
            display: 'flex',
            marginBottom: '20px',
        },
        tab: {
            flex: 1,
            padding: '10px',
            backgroundColor: '#f1f1f1',
            border: 'none',
            cursor: 'pointer',
            textAlign: 'center',
        },
        activeTab: {
            backgroundColor: '#25A3AF',
            color: 'white',
        },
        tabContent: {
            padding: '10px',
        },
    };

    return (
        <div className="wrapper">
            {sidebarToggle ? (
                <>
                    <MiniSidebar />
                </>
            ) : (
                <>
                    <Sidebar />
                </>
            )}
            <main className="content">
                <ToastContainer />
                <div className="container-fluid p-0">
                    <div className="row d-flex">
                        <div className="col-xl-12 col-xxl-12">
                            <div style={styles.card}>
                                {/* Tabs */}
                                <div style={styles.tabs}>
                                    <button
                                        style={activeTab === 'signup' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
                                        onClick={() => setActiveTab('signup')}
                                    >
                                        Sign-Up
                                    </button>
                                    <button
                                        style={activeTab === 'login' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
                                        onClick={() => setActiveTab('login')}
                                    >
                                        Login
                                    </button>
                                    <button
                                        style={activeTab === 'contact' ? { ...styles.tab, ...styles.activeTab } : styles.tab}
                                        onClick={() => setActiveTab('contact')}
                                    >
                                        Contact
                                    </button>
                                </div>

                                {/* Tab Content */}
                                <div>
                                    {activeTab === 'signup' && (
                                        <div style={styles.tabContent}>
                                            <h3>Sign-Up Form</h3>
                                            <form>
                                                <label>
                                                    Name: <input type="text" name="name" />
                                                </label>
                                                <br />
                                                <label>
                                                    Email: <input type="email" name="email" />
                                                </label>
                                                <br />
                                                <button type="submit">Sign Up</button>
                                            </form>
                                        </div>
                                    )}
                                    {activeTab === 'login' && (
                                        <div style={styles.tabContent}>
                                            <h3>Login Form</h3>
                                            <form>
                                                <label>
                                                    Email: <input type="email" name="email" />
                                                </label>
                                                <br />
                                                <label>
                                                    Password: <input type="password" name="password" />
                                                </label>
                                                <br />
                                                <button type="submit">Login</button>
                                            </form>
                                        </div>
                                    )}
                                    {activeTab === 'contact' && (
                                        <div style={styles.tabContent}>
                                            <h3>Contact Form</h3>
                                            <form>
                                                <label>
                                                    Message: <textarea name="message"></textarea>
                                                </label>
                                                <br />
                                                <button type="submit">Send</button>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};



export default WidgetTabs;
