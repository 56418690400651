import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import FetchFamilyTransactionDatatable from "./FetchFamilyTransactionDatatable.js";
import "../assets/css/customDatepicker.css";
import { Link, useParams } from "react-router-dom";
import { getOwedBalance } from "../../../services/invoiceService.js";
import { useUserDataContext } from "../../../contextApi/userDataContext.js";
import IconDatePicker from "../../form/date-picker-input/IconDatePicker";

const TransactionByFamily = () => {
    const {
        fetchTransactionsByFamily,
    } = useUserDataContext();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chargecat_name, set_chargecat_name] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [owedBalance, setOwedBalance] = useState("");
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const param = useParams();

    const handleDateChange = async (date) => {
        setSelectedDate(date);
        const response = await getOwedBalance(param.id, date);
        if (response?.success) {
            setOwedBalance(response.data.amount);
            fetchTransactionsByFamily(fromDate, toDate);
        } else {
            // Handle error or set a default value
            setOwedBalance(0);
        }
    };

    const owedBalanceHandler = async () => {
        const response = await getOwedBalance(param.id);
        // console.table("owed balance response: by table------", response)
        if (response?.success) {
            setOwedBalance(response.data.amount);
        } else {
            // Handle error or set a default value
            setOwedBalance(0);
        }
    }

    useEffect(() => {
        owedBalanceHandler();
        fetchTransactionsByFamily(null, fromDate, toDate);
    }, [])


    return <div className="row">
        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
            <div className="card flex-fill w-100">
                <div className="card-header w-100 pb-0">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>

                        <div class="remianing-amount-format">
                            <p>
                                <span>Balance remaining: <strong> {owedBalance} </strong> as of test </span>
                                <div className="row" style={{ paddingTop: "20px" }}>
                                    <div className="col-md-6">
                                        <label>From Date</label>
                                        <IconDatePicker
                                            selectedDate={fromDate}
                                            onChange={(date) => handleDateChange(date, toDate)}
                                            minDate={null}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>To Date</label>
                                        <IconDatePicker
                                            selectedDate={toDate}
                                            onChange={(date) => handleDateChange(fromDate, date)}
                                            minDate={fromDate || new Date()}
                                        />
                                    </div>
                                </div>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card-body d-flex">
                    <div className="align-self-center w-100">
                        <FetchFamilyTransactionDatatable setIsEdit={setIsEdit} setModalIsOpen={setModalIsOpen} set_chargecat_name={set_chargecat_name} setSelectedId={setSelectedId} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default TransactionByFamily;