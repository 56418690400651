import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { NON_LOGGED_IN_API_URL } from "../../utils/config.js";
import axios from "axios";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { useTokenStorage, checkAuthAndRedirect } from '../../utils/helper.js';
import { useUserDataContext } from "../../contextApi/userDataContext.js";
import { getDomainName } from "../../services/loginService.js";
import i18next from "i18next";
import LanguageOption from "../LanguageOption.js";
import { useTranslation } from "react-i18next";
import "./style.css";
import useGlobalSettings from "../../hooks/useGlobalSettings.js";


const Register = ({ subdomain, setSubdomain }) => {
  const { t } = useTranslation();
  const { getSetting } = useGlobalSettings();
  const { setIsDarkMode, centralPortalDomain, getAndSetDomainName } = useUserDataContext();
  const navigate = useNavigate();
  const firstNameRef = useRef(null);
  const [userdetails, setUserdetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    rpassword: "",
    domain: "",
    bname: "",
    business_size: "",
    currency: "",
  });
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(eyeOff);
  const [error, setError] = useState({});
  const storeToken = useTokenStorage();

  const multiLangHandler = (e) => {
    // i18next.changeLanguage(e.target.value);
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };
  const handleChange = (e) => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;

    setUserdetails({ ...userdetails, [name]: value });
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(eye);
      setType("text");
    } else {
      setIcon(eyeOff);
      setType("password");
    }
  };

  const handleSubmit = async () => {
    const subdomain = userdetails.domain;
    const formData = {
      first_name: userdetails.firstname,
      email: userdetails.email,
      password: userdetails.password,
      c_password: userdetails.rpassword,
      last_name: userdetails.lastname,
      domain: subdomain,
      bname: userdetails.bname,
      business_size: userdetails.business_size,
      // country_detail: countryDetail,
      currency: userdetails.currency
    };
    if (isTermsChecked) {
      formData.terms = isTermsChecked;
    }
    setSubdomain(subdomain);

    const config = {
      method: "POST",
      url: `${NON_LOGGED_IN_API_URL}register`,
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(formData),
    };
    await axios(config)
      .then(async (response) => {
        if (response.status === 200) {
          var token = response.data.data.token;
          var role = response.data.data.role_id;

          //store token in localstorage
          var domain = `${subdomain}.${centralPortalDomain}`;
          storeToken(token, domain);

          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });

          const encodedToken = encodeURIComponent(token);
          const encodedDomain = encodeURIComponent(domain);
          const encodedRole = encodeURIComponent(role);

          window.location.href = `${process.env.REACT_APP_PROTOCOL}://${subdomain}.${centralPortalDomain}/dashboard#${encodedToken}#${encodedDomain}#${encodedRole}`;
        }
      })
      .catch((error) => {
        if (error?.response?.data?.success === false) {
          setError(error.response.data.data);
        }
      })
  };

  useEffect(() => {
    firstNameRef.current.focus();
    document?.documentElement?.setAttribute("data-theme", "light");
    setIsDarkMode(false);
    getAndSetDomainName();
    checkAuthAndRedirect(navigate, 'Register', getSetting("centralPortalDomain"), getSetting('authToken'));
  }, []);

  function validateInput(event) {
    const regex = /^[a-zA-Z0-9.-]*$/; // Only allow letters, digits, dot, and hyphen
    const key = String.fromCharCode(event.keyCode || event.which);
    if (event.key === 'Enter') {
      handleSubmit();
    }
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
    return true;
  }

  return (
    <div className="d-md-flex align-items-center justify-content-center primary-bg">
      <ToastContainer />
      <div className="contents">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-12">
              {/* <LanguageOption onChange={(e) => multiLangHandler(e)} /> */}
              <div className="form-block mx-auto">
                <div className="text-center mb-5">
                  <h3>
                    <strong>{t("tutorpad")}</strong>
                  </h3>
                </div>
                <form>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("register.firstName")}
                      name="firstname"
                      onKeyPress={handleKeyPress}
                      onChange={handleChange}
                      required
                      ref={firstNameRef}
                    />
                    <small className="input-error-message" >
                      {error?.first_name?.length ? error.first_name[0] : <></>}
                    </small>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("register.lastName")}
                      name="lastname"
                      onChange={handleChange}
                      onKeyPress={handleKeyPress}
                      required
                    />
                    <small className="input-error-message">
                      {error?.last_name?.length ? error.last_name[0] : <></>}
                    </small>
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      placeholder={t("register.emailPlaceholder")}
                      name="email"
                      onKeyPress={handleKeyPress}
                      onChange={handleChange}
                      required
                    />
                    <small className="input-error-message">
                      {error?.email?.length ? error.email[0] : <></>}
                    </small>
                  </div>
                  <div className="form-group last mb-3 pass">
                    <input
                      type={type}
                      className="form-control"
                      placeholder={t("register.passwordPlaceholder")}
                      name="password"
                      onKeyPress={handleKeyPress}
                      onChange={handleChange}
                      required
                    />
                    <span className="eyeball" onClick={handleToggle}>
                      <Icon className="absolute mr-10" icon={icon} size={15} />
                    </span>
                    <small className="input-error-message">
                      {error?.password?.length ? error.password[0] : <></>}
                    </small>
                  </div>
                  <div className="form-group last mb-3">
                    <input
                      type="password"
                      className="form-control"
                      placeholder={t("register.reEnterPassword")}
                      name="rpassword"
                      onKeyPress={handleKeyPress}
                      onChange={handleChange}
                      required
                    />
                    <small className="input-error-message">
                      {error?.c_password?.length ? error.c_password[0] : <></>}
                    </small>
                  </div>
                  <div className="form-group last mb-3">
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        className="form-control domain"
                        placeholder={t("register.domain")}
                        name="domain"
                        onChange={handleChange}
                        onKeyPress={(e) => validateInput(e)}
                      />
                      <span style={{ fontSize: "16px", paddingLeft: "10px" }}>{centralPortalDomain}</span>
                      {/* <span style={{ fontSize: "16px"}}>tutorpad.co</span> */}
                    </div>
                    <small className="input-error-message">
                      {error?.domain?.length ? error.domain[0] : <></>}
                    </small>
                  </div>

                  <div className="form-group last mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder={t("register.businessName")}
                      name="bname"
                      onKeyPress={handleKeyPress}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="form-group last mb-3">
                    <select
                      className="form-control"
                      name="business_size"
                      onChange={handleChange}
                      required
                    >
                      <option value="" disabled selected>{t("register.businessType")}</option>
                      <option value="single">{t("register.businessTypeOptions.operateAlone")}</option>
                      <option value="multi">
                        {t("register.businessTypeOptions.multipleTutors")}
                      </option>
                    </select>
                    <small className="input-error-message">
                      {error?.business_size?.length ? (
                        error.business_size[0]
                      ) : (
                        <></>
                      )}
                    </small>
                  </div>

                  <div className="form-group  last mb-3">
                    <div className="d-sm-flex align-items-center justify-content-between">
                      <div className="form-check">
                        <input type="checkbox" name="terms" 
                              className="remember-me-checkbox" id="rememberMe"
                              checked={isTermsChecked} 
                              onChange={() => setIsTermsChecked(!isTermsChecked)} />
                        <label className="form-check-label agree-label" htmlFor="rememberMe">
                          {t("register.iAagree")} <Link to="/">{t("register.termsOfService")}</Link>{" "}
                          and <Link to="/">{t("register.privacyPolicy")}</Link>
                        </label>
                      </div>
                    </div>
                    <small className="input-error-message">
                      {error?.terms?.length ? error.terms[0] : <></>}
                    </small>
                  </div>

                  <input
                    type="button"
                    value={t("createAccount")}
                    className="btn btn-block btn-primary mt-2"
                    onClick={handleSubmit}
                  />
                </form>
                <br></br>
                {t("alreadyAccount")}<Link to="/domain-signin"> {t("signInHere")} </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
