import React, { useState, useEffect } from "react";
import CurrencyService from "../../services/currencyService";

const AttendancePage = ({
  attendance_types,
  set_attendance_types,
  attendance_status,
  set_attendance_status,
  billing_type,
  set_billing_type,
  is_late,
  set_is_late,
  lesson_price,
  set_lesson_price,
  is_paid_at_lesson,
  set_is_paid_at_lesson,
  lessionVal,
  paid_amount,
  set_paid_amount
}) => {
  const selectedCurrency = CurrencyService.getCurrency();

  useEffect(() => {
    if (attendance_status == 1) {
      set_billing_type("");
      set_lesson_price(lessionVal);
      set_is_late(false);
      set_is_paid_at_lesson(is_paid_at_lesson);
    } else if (attendance_status == 2) {
      set_billing_type("lesson_is_billable");
      set_lesson_price(lessionVal);
      set_is_late(false);
      set_is_paid_at_lesson(is_paid_at_lesson);
    } else if (attendance_status == 3) {
      set_billing_type("given_notice");
      set_lesson_price(lessionVal);
      set_is_late(false);
      set_is_paid_at_lesson(is_paid_at_lesson);
    } else if (attendance_status == 4) {
      set_billing_type("not_billable");
      set_lesson_price(lessionVal);
      set_is_late(false);
      set_is_paid_at_lesson(is_paid_at_lesson);
    }
  }, [attendance_status]);
  console.log("attendence types------------", attendance_types);
  
  return (
    <>
      <label className="title">Attendance Status</label>
      <div className="attendance-box">
        {attendance_types.map((e) => {
          return (
            <label key={e.id}>
              <input
                type="radio"
                value={e.id}
                onChange={() => {
                  set_attendance_status(e.id);
                  
                  set_billing_type("");
                }}
                name="attendance_status"
                checked={attendance_status === e.id}
              />
              <span>{e.as_title}</span>
            </label>
          );
        })}
      </div>
      {attendance_status != 1 && (
        <div>
          <div className="attendance-box">
            {attendance_status == 2 && (
              <label>
                <input
                  type="checkbox"
                  checked={is_late}
                  onChange={(e) => set_is_late(e.target.checked)}
                  name="is_late"
                />
                <span>Student was late</span>
              </label>
            )}
          </div>
          <label className="title">Attendance Billing</label>
          <div className="attendance-box">
            {attendance_status == 2 && (
              <label>
                <input
                  type="radio"
                  value={"lesson_is_billable"}
                  checked={billing_type == "lesson_is_billable"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Lesson is Billable</span>
              </label>
            )}
            {(attendance_status == 2 || attendance_status == 3) && (
              <label>
                <input
                  type="radio"
                  value={"used_makeup_credits"}
                  checked={billing_type == "used_makeup_credits"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Use Make-Up Credit</span>
              </label>
            )}
          </div>
          <div className="attendance-box">
            {attendance_status == 3 && (
              <label>
                <input
                  type="radio"
                  value={"given_notice"}
                  checked={billing_type == "given_notice"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Notice Given (Not Billable)</span>
              </label>
            )}
            {attendance_status == 4 && (
              <label>
                <input
                  type="radio"
                  value={"not_billable"}
                  checked={billing_type == "not_billable"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Not Billable</span>
              </label>
            )}
            {attendance_status == 3 && (
              <label>
                <input
                  type="radio"
                  value={"absence_is_billable"}
                  checked={billing_type == "absence_is_billable"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Absence is Billable</span>
              </label>
            )}
            {(attendance_status == 3 || attendance_status == 4) && (
              <label>
                <input
                  type="radio"
                  value={"give_makeup_credit"}
                  checked={billing_type == "give_makeup_credit"}
                  onChange={(e) => set_billing_type(e.target.value)}
                  name="billing_type"
                />
                <span>Bill & Give Make-Up Credit</span>
              </label>
            )}
          </div>        
          
          <div className="attendance-box">
            {(billing_type == "lesson_is_billable" ||
              billing_type == "absence_is_billable" ||
              billing_type == "give_makeup_credit") && (
              <label>
                <span className="title">Lesson Price</span>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                      <span className="input-group-text" style={{ height: '100%' }}>{selectedCurrency}</span> {/* This is where the currency symbol is displayed */}
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    value={lesson_price}
                    onChange={(e) => set_lesson_price(e.target.value)}
                    name="lesson_price"
                  />
                </div>
              </label>
              
            )}
            {attendance_status == 2 && billing_type == "lesson_is_billable" && (
              <label>
                <input
                  type="checkbox"
                  checked={is_paid_at_lesson}
                  onChange={(e) => set_is_paid_at_lesson(e.target.checked)}
                  name="is_paid_at_lesson"
                />
                <span>Student paid at lesson</span>
              </label>
            )}
            {is_paid_at_lesson && (
                <label>
                <span className="title">Amount Paid</span>
                <div className="input-group mb-3">
                  <div className="input-group-prepend">
                      <span className="input-group-text" style={{ height: '100%' }}>{selectedCurrency}</span>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    value={paid_amount}
                    onChange={(e) => set_paid_amount(e.target.value)}
                    name="paid_amount"
                  />
                </div>
              </label>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AttendancePage;
