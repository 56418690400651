import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import FetchTransactionDatatable from "./FetchTransactionDatatable.js";
import "../users/assets/css/customDatepicker.css";
import { Link, useParams } from "react-router-dom";
import { getOwedBalance } from "../../services/invoiceService.js";
import IconDatePicker from "../form/date-picker-input/IconDatePicker";
import { convertDateForSentInApi } from '../../services/dateService';

const Transaction = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [chargecat_name, set_chargecat_name] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const [fromDate, setFromDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
    const [toDate, setToDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0));
    const param = useParams();

    return <div className="row">
        <div className="col-12 col-md-12 col-xxl-12 d-flex order-2 order-xxl-3">
            <div className="card flex-fill w-100">
                <div className="card-header w-100">
                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        <Link to={"/families-and-invoices/transaction-type/1/" + param.id}></Link>
                        <div className="row">
                            <div className="col-md-6">
                                <label>From Date</label>
                                <IconDatePicker
                                    selectedDate={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    minDate={null}
                                    />
                            </div>
                            <div className="col-md-6">
                                <label>To Date</label>
                                <IconDatePicker
                                    selectedDate={toDate}
                                    onChange={(date) => setToDate(date)}
                                    minDate={fromDate || new Date()}
                                    />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body d-flex">
                    <div className="align-self-center w-100">
                        <FetchTransactionDatatable fromDate={fromDate} toDate={toDate} setIsEdit={setIsEdit} setModalIsOpen={setModalIsOpen} set_chargecat_name={set_chargecat_name} setSelectedId={setSelectedId} />
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default Transaction;